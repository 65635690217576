import vehicule from '@amo/core/assets/images/AMO_Icons/SearchVehicule.svg';
import { Question } from '@amo/core/components/containers';
import {getTranslation} from "@amo/core/utils/index.js";

const GoToVehicule = (props) => {
    const { nextQuestion } = props;

    return (
        <Question icon={vehicule} title={getTranslation("vehicle.welcome.hello")}>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-5 f-18'}
                       dangerouslySetInnerHTML={{__html: getTranslation("vehicle.welcome.title")}}/>

                    <p className={'mt-3 f-18'}
                       dangerouslySetInnerHTML={{__html: getTranslation("vehicle.welcome.someQuestions")}}/>

                    <p className={'mt-3 f-18'}>{getTranslation("vehicle.welcome.beforeStart")}</p>
                    <ul className={'text-start mt-3 ms-5'}>
                        <li>{getTranslation("vehicle.welcome.yourDrivingLicense")}</li>
                        <li dangerouslySetInnerHTML={{__html: getTranslation("vehicle.welcome.registrationDocumentVehicle")}}/>
                        <li dangerouslySetInnerHTML={{__html: getTranslation("vehicle.welcome.informationStatement")}}/>
                    </ul>

                    <p className={'f-26 fw-bold text-secondary'}>{getTranslation("vehicle.welcome.areYouReady")}</p>
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                {getTranslation("vehicle.welcome.letsGo")}
            </button>
        </Question>
    );
};

export default GoToVehicule;
