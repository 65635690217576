import Chat from '@amo/core/assets/images/AMO_Icons/Chat.svg';
import { Question } from '@amo/core/components/containers';
import {getTranslation} from "@amo/core/utils/functions.js";

const GoToAntecedents = (props) => {
    const { nextQuestion } = props;

    return (
        <Question icon={Chat} title={getTranslation('profil.goToAntecedents.title')}>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'} dangerouslySetInnerHTML={{__html: getTranslation('profil.goToAntecedents.text')}} />
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                {getTranslation('profil.goToAntecedents.button')}
            </button>
        </Question>
    );
};

export default GoToAntecedents;
