import Carte_Grise from '@amo/core/assets/images/AMO_Icons/Carte_Grise.svg';
import { Question } from '@amo/core/components/containers';
import {getTranslation} from "@amo/core/utils/functions.js";

const GoToCoordonnees = (props) => {
    const { nextQuestion } = props;

    return (
        <Question
            icon={Carte_Grise}
            title={getTranslation('yourNeedes.goToCoordonnees.title')}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'}>{getTranslation('yourNeedes.goToCoordonnees.text')}</p>
                    <p className={'mt-3 f-18'} dangerouslySetInnerHTML={{__html: getTranslation('yourNeedes.goToCoordonnees.textBis')}} />
                    <p className={'mt-3 f-18'}>{getTranslation('yourNeedes.goToCoordonnees.textTer')}</p>
                </div>
            </div>
            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                {getTranslation('yourNeedes.goToCoordonnees.button')}
            </button>
        </Question>
    );
};

export default GoToCoordonnees;
