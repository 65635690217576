import tchat from '@amo/core/assets/images/AMO_Icons/Tout-motards.svg';
import _ from 'lodash';
import moment from 'moment';
import NumeroPermis from "../NumeroPermis/NumeroPermis.jsx";
import {getTranslation} from "@amo/core/utils/index.js";

const RecapPersonne = (props) => {
    const { data, goTo } = props;
    const IndexSouscripteur =
        _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]') === 'true' ||
        _.get(data, 'DemandeTarif[ListePersonnes][1][Civilite]') === '3'
            ? 1
            : 0;

    return (
        /*<div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                <div className={'p-3 bg-white rounded border'}>
                    <div className={'row'}>
                        <div className="col-2">
                            <img src={tchat} alt="Informations souscripteur" className={'pt-2'}/>
                        </div>

                        <div className="col-10 text-start">
                            <p className={'f-16 text-dark text-start fw-bold'}>
                                {getTranslation('summary.yourInformations')} {' '}
                                <span
                                    className={'text-secondary f-12 float-end cursor-pointer underline-link'}
                                    onClick={() => goTo({step: 4, question: 1})}
                                >
                                    {getTranslation('update')}
                                </span>
                            </p>
                            <small className={'d-block f-13 text-dark fw-bold'}>
                                {`${
                                    _.get(data, `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Prenom]`) || ''
                                } ${_.get(data, `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Nom]`)}`}
                            </small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Email]`)}
                            </small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][TelFixe]`)}
                            </small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Adresse1]`)}
                            </small>
                            {_.get(data, `DemandeTarif[ListePersonnes][0][Adresse2]`) && (
                                <small className={'d-block f-13'}>
                                    {_.get(data, `DemandeTarif[ListePersonnes][0][Adresse1]`)}
                                </small>
                            )}
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][CP]`)}{' '}
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Ville]`)}
                            </small>
                            <br/>
                            <small className={'d-block f-13 text-dark fw-bold'}>Conducteur :</small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Prenom]`)}{' '}
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Nom]`)}
                            </small>
                            {_.map(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis, i) => {
                                if (permis?.TypePermis) {
                                    return (
                                        <small className={'d-block f-13'} key={i}>
                                            {getTranslation('summary.license')} {permis.TypePermis} (
                                            {moment(permis.DatePermis, 'DD/MM/YYYY').format('L')})
                                        </small>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>*/

            <div className="col-lg-4 col-md-12 border-end">
                <div className={'row'}>
                    <div className="col-2"><img src={tchat} alt="Informations souscripteur" width={40}/></div>
                    <div className="col-10 text-start">
                        <p className={'f-14 text-primary text-start fw-bold'}>
                            Mes informations
                            <span className={'text-secondary f-12 float-end cursor-pointer underline-link'}
                                  onClick={() => goTo({step: 4, question: 1})}
                            >
                                modifier
                            </span>
                        </p>
                        <div className={'d-block f-13'}>
                            <p>
                                {_.get(data, `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Prenom]`) || ''}
                                {' '}
                                {_.get(data, `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Nom]`)}
                            </p>
                            <p>{_.get(data, `DemandeTarif[ListePersonnes][0][Email]`)}</p>
                            <p>{_.get(data, `DemandeTarif[ListePersonnes][0][TelFixe]`)}</p>
                            <p>{_.get(data, `DemandeTarif[ListePersonnes][0][Adresse1]`)}</p>
                            {_.get(data, `DemandeTarif[ListePersonnes][0][Adresse2]`) &&
                                <p>{_.get(data, `DemandeTarif[ListePersonnes][0][Adresse1]`)}</p>
                            }
                            <p>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][CP]`)}
                                {' '}
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Ville]`)}
                            </p>
                            <br/>
                            <p className={'f-14 text-primary fw-bold'}>Conducteur</p>
                            <p>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Prenom]`)}
                                {' '}
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Nom]`)}
                            </p>
                            {_.map(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis, i) => {
                                if (permis?.TypePermis) {
                                    return (
                                        <p key={i}>
                                            Permis {permis.TypePermis} (
                                            {moment(permis.DatePermis, 'DD/MM/YYYY').format('L')})
                                        </p>
                                    );
                                }
                            })}

                            <NumeroPermis {...props} />
                        </div>
                    </div>
                </div>
            </div>
    )
};

export default RecapPersonne;
