import NonInclus from '@amo/core/assets/images/icons/non_inclus.svg';
import Star from '@amo/core/assets/images/icons/star.png';
import Moto from '@amo/core/assets/images/AMO_Icons/Moto.svg';
import MotoWhite from '@amo/core/assets/images/AMO_Icons/Moto-white.svg';
import {RadioBtn} from '@amo/core/components/forms';
import _ from 'lodash';
import {Field} from 'redux-form';
import Slider from "react-slick";
import getPriceByFractionnementOrCodePromo from '../../utilsTarif/getPriceByFractionnementOrCodePromo';
import './table.scss';
import {getTranslation} from "@amo/core/utils/index.js";

const TableFormule = (props) => {
    const {formulePreconisee, formules, formuleChoisie, fractionnementChoisi, codePromo, analyticsSetEvent, changeValue, vehicule} = props;

    const convertPriceToFloat = (price) => parseFloat(price.replace('€', '').trim().replace(',', '.'));

    const buildLabelFormule = (f) => {
        const priceData = getPriceByFractionnementOrCodePromo(f, fractionnementChoisi);
        const isPromoEligible = codePromo?.Eligible && convertPriceToFloat(priceData.prix) > convertPriceToFloat(priceData.prixPromo);

        return `
            <span data-cy="title" class="${f.CodeFormule === formuleChoisie?.CodeFormule ? 'text-warning' : 'text-dark'} d-block fw-bold f-24">${f.LibelleFormule}</span>
            <span data-cy="price" class="${f.CodeFormule === formuleChoisie?.CodeFormule ? 'text-white' : 'text-primary'} d-block h4 f-40 fw-bold text-nowrap">${isPromoEligible ? priceData.prixPromo : priceData.prix} 
            <small class="${f.CodeFormule === formuleChoisie?.CodeFormule ? 'text-white' : 'text-primary'}">TTC/${fractionnementChoisi === 'M' ? getTranslation('months') : getTranslation('year')}</small></span>
            ${isPromoEligible ? `<span class="d-block text-muted text-decoration-line-through">${priceData.prix} <small>TTC/${fractionnementChoisi === 'M' ? getTranslation('months') : getTranslation('year')}</small></span>` : ''}
            <hr/>        
        `;
    };

    const renderStars = (formule) => {
        const starCount = formule.CodeFormule === 'F1' ? 1 : formule.CodeFormule === 'F2' ? 2 : 3;
        return (
            <div className="stars-container d-flex justify-content-center mt-2">
                {_.times(starCount, (i) => (
                    <img key={i} src={Star} alt="Star" className="star-icon mx-1" width={30}/>
                ))}
            </div>
        );
    };

    const renderDescription = (formule) => {
        const {brand} = vehicule || {};
        const descriptions = {
            F1: getTranslation('pricing.descriptionPlanF1', {brand: brand?.name}),
            F2: getTranslation('pricing.descriptionPlanF2', {brand: brand?.name}),
            F3: getTranslation('pricing.descriptionPlanF4', {brand: brand?.name}),
            F4: getTranslation('pricing.descriptionPlanF4', {brand: brand?.name}),
        };
        return <p className="f-13 mx-2" dangerouslySetInnerHTML={{__html: descriptions[formule.CodeFormule]}}/>;
    };

    const renderGuarantees = (formule, allGuarantees) => (
        <div className="row mt-3">
            {allGuarantees.map((garantie, index) => {
                const isIncluded = _.find(formule.ListeGaranties, (h) => h.CodeGarantie === garantie.CodeGarantie && h.Disponible === 'Inclusion');
                return (
                    <div
                        key={index}
                        className={`p-2 f-13 text-start d-flex align-items-center ${index === allGuarantees.length - 1 ? 'pb-5' : ''}`}
                    >
                        {isIncluded
                            ? <span className="check-icon w-20 me-2"></span>
                            : <img src={NonInclus} alt="Non Inclus" className="me-2" width={20}/>}
                        <span className={isIncluded ? '' : 'text-grey'}>{garantie.LibelleGarantie}</span>
                    </div>
                );
            })}
        </div>
    );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    const lastFormula = _.last(formules);
    const inclusionGuarantees = lastFormula?.ListeGaranties.filter(g => g.Disponible === 'Inclusion') || [];

    const renderCard = (f) => {
        const priceData = getPriceByFractionnementOrCodePromo(f, fractionnementChoisi);
        const priceToDisplay = priceData.prixPromo && codePromo?.Eligible ? priceData.prixPromo : priceData.prix;

        const handleCardClick = () => {
            analyticsSetEvent({
                event: 'changementFormule',
                datas: {changementFormule: {formule: f.LibelleFormule.toUpperCase()}},
            });
            changeValue(`DemandeTarif[Police][FormuleChoisie]`, f.CodeFormule);
            changeValue(`options`, {});
            changeValue(`optionsFranchise`, {RAFRAVI: 'false', RAFRADTA: 'false'});
            changeValue(`optionsListe`, {});
        };

        return (
            <div
                className={`col border p-3 rounded-3 d-flex flex-column shadow position-relative
                ${f.CodeFormule === formuleChoisie?.CodeFormule ? 'bg-primary-dark text-white' : 'border-ternary'}`}
                key={f.CodeFormule}
                onClick={handleCardClick} // Bloc entier cliquable
                style={{cursor: 'pointer', minWidth: 330}}
            >
                {
                    formulePreconisee === f.CodeFormule && <div className="ribbon"><span> Recommandée</span></div>
                }

                <div className="text-center flex-grow-1 d-flex flex-column pb-5">
                    <div className="text-center">
                        <span
                            className="d-block"
                            dangerouslySetInnerHTML={{__html: buildLabelFormule(f)}}
                        />
                        {renderStars(f)}
                        {f.CodeFormule === formuleChoisie?.CodeFormule ?
                            <img src={MotoWhite} alt="Moto" className="star-icon d-inline"/> :
                            <img src={Moto} alt="Moto" className="star-icon d-inline"/>}
                        {renderDescription(f)}
                    </div>
                    {renderGuarantees(f, inclusionGuarantees)}
                </div>
                <Field
                    component={RadioBtn}
                    type="radio"
                    name="DemandeTarif[Police][FormuleChoisie]"
                    value={f.CodeFormule}
                    label=""
                    className="cursor-pointer p-z3 w-100 margin-moins"
                    onClick={(e) => {
                        e.stopPropagation(); // Empêche le clic sur le bouton de déclencher l'événement du bloc
                        handleCardClick();
                    }}
                >
                    {f.CodeFormule === formuleChoisie?.CodeFormule
                        ? <p className={`btn btn-primary-light btn-smaller cursor-pointer f-12`}>
                            <span className={'check-icon w-20'}/> {getTranslation('pricing.myFormule')}
                        </p>
                        : <p
                            className={`btn btn-primary-dark btn-smaller cursor-pointer f-12`}
                            dangerouslySetInnerHTML={{
                                __html: fractionnementChoisi === 'M'
                                    ? getTranslation('pricing.choiceThisFormuleMonthly', {price: priceToDisplay})
                                    : getTranslation('pricing.choiceThisFormuleAnnualy', {price: priceToDisplay})
                            }}
                        />
                    }
                </Field>
            </div>
        );
    };

    return (
        <div className="container py-4">
            <div className="d-none d-md-flex row d-flex justify-content-center gap-3 h-100 flex-nowrap">
                {formules && _.map([...formules], renderCard)}
            </div>
            <div className="d-block d-md-none">
                <Slider {...settings}>
                    {formules && _.map(_.reverse([...formules]), renderCard)}
                </Slider>
            </div>
        </div>
    );
};

export default TableFormule;