import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/functions.js";
import {useEffect} from "react";

const TitulaireMajeur = (props) => {
    const { data, changeValue, nextQuestion, dejaClientData } = props;
    const isDejaClient = !_.isEmpty(dejaClientData);
    const isSociety = _.get(data, 'DemandeTarif[ListePersonnes][1][TypePersonne]') === 'M'

    return (
        <>
            { (!isDejaClient || !isSociety) &&
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'titulaireCarteGrise'}
                        value={'1'}
                        label={getTranslation("profil.holderRegistrationVehicle.yourName")}
                        onChange={() => {
                            changeValue('DemandeTarif[ListePersonnes]', [
                                { ..._.get(data, 'DemandeTarif[ListePersonnes][0]') },
                            ]);
                            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true);
                            nextQuestion(300);
                        }}
                    />
                </div>
            }
            {(!_.includes(['quad', 'verte'], data?.typeVehicule) && (!isDejaClient || isSociety)) && (
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'titulaireCarteGrise'}
                        value={'2'}
                        label={getTranslation("profil.holderRegistrationVehicle.companyName")}
                        onChange={() => {
                            changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'M');
                            changeValue('DemandeTarif[ListePersonnes][1][Civilite]', '3');
                            changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                            nextQuestion(300);
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default TitulaireMajeur;
