import _ from 'lodash';
import { createSelector } from 'reselect';

/* Init */
const initSelector = (state) => state.init;
const demandeContratSelector = (state) => state.init.data.DemandeContrat || {};
const paiementSelector = (state) => state.init.payments;

export const getState = createSelector(initSelector, (init) => init.state);

export const getLinks = createSelector(
    initSelector,
    (init) => (init.links) || null,
);

export const getIsDevis = createSelector(getState, (state) => state === 'devis');

export const getToken = createSelector(initSelector, (init) => init.token);

export const getStepInit = createSelector(initSelector, (init) => init.step);

export const getQuestionInit = createSelector(initSelector, (init) => init.question);

export const getDocumentsUrlInit = createSelector(initSelector, (init) => init.documents_url);

export const getCodePromoDemandeTarifInit = createSelector(
    initSelector,
    (init) => init.data.DemandeTarif.CodePromo?.CodePromo,
);

export const getListesPersonnesODemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.ListePersonnes[0]
)

export const getConnuCommentInit = createSelector(initSelector, (init) =>
    init.data.DemandeDevis?.ConnuComment !== undefined ? String(init.data.DemandeDevis.ConnuComment) : undefined,
);

export const getGPDemandeTarifInit = createSelector(initSelector, (init) =>
    init.data.DemandeTarif.GoodbyePack !== undefined ? init.data.DemandeTarif.GoodbyePack : undefined,
);

export const getParrainageDemandeTarifInit = createSelector(initSelector, (init) =>
    init.data.DemandeTarif.Parrainage,
);

export const getDevisHamonDemandeTarifInit = createSelector(initSelector, (init) =>
    init.data.DemandeTarif.DevisHamon !== undefined ? String(init.data.DemandeTarif.DevisHamon) : '0',
);

export const getDevisHamonAssureurActuelIdDemandeTarifInit = createSelector(initSelector, (init) =>
    init.data.DemandeTarif.DevisHamonAssureurActuelId !== undefined
        ? init.data.DemandeTarif.DevisHamonAssureurActuelId
        : undefined,
);

export const getDevisHamonAssureurActuelNameDemandeTarifInit = createSelector(initSelector, (init) =>
    init.data.DemandeTarif.DevisHamonAssureurActuelName !== undefined
        ? init.data.DemandeTarif.DevisHamonAssureurActuelName
        : undefined,
);


export const getDevisHamonContratActuelDemandeTarifInit = createSelector(initSelector, (init) =>
    init.data.DemandeTarif.DevisHamonContratActuel !== undefined
        ? init.data.DemandeTarif.DevisHamonContratActuel
        : undefined,
);

export const getAssureMotoInit = createSelector(initSelector, (init) => init.data.assure_moto);

export const getAssureAutoInit = createSelector(initSelector, (init) => init.data.assure_auto);

export const getTitulaireCarteGriseInit = createSelector(initSelector, (init) => init.data.titulaireCarteGrise);

/* DemandeDevis */
export const getOptinNewsletterDemandeDevisInit = createSelector(initSelector, (init) =>
    init.data.DemandeDevis && init.data.DemandeDevis.OptinNewsletter !== undefined
        ? String(init.data.DemandeDevis.OptinNewsletter)
        : undefined,
);
export const getOptinUtilisationDonneesDemandeDevisInit = createSelector(initSelector, (init) =>
    init.data.DemandeDevis && init.data.DemandeDevis.OptinUtilisationDonnees !== undefined
        ? String(init.data.DemandeDevis.OptinUtilisationDonnees)
        : undefined,
);

export const getIsSendMailPaiement = createSelector(
    demandeContratSelector,
    (contrat) => contrat.ModePaiementComptant === 'M',
);

/* DemandeContrat */
export const getDemandeContratInit = createSelector(initSelector, (init) => init.data.DemandeContrat || {});
export const getSignatureEmailDemandeContratInit = createSelector(
    getDemandeContratInit,
    (demandeContrat) => demandeContrat.SignatureEmail,
);
export const getSignaturePhoneDemandeContratInit = createSelector(
    getDemandeContratInit,
    (demandeContrat) => demandeContrat.SignaturePhone,
);
export const getCPDemandeContratInit = createSelector(getDemandeContratInit, (demandeContrat) => demandeContrat.cpIBAN);

export const getDevisHamonDemandeContratInit = createSelector(getDemandeContratInit, (demandeContrat) =>
    demandeContrat && demandeContrat.DevisHamon !== undefined ? demandeContrat.DevisHamon : undefined,
);

export const getDateHeureEffetDemandeContratInit = createSelector(
    getDemandeContratInit,
    (demandeContrat) => demandeContrat.DateHeureEffet
)

export const getDevisHamonAssureurActuelIdDemandeContratInit = createSelector(
    getDemandeContratInit,
    (demandeContrat) =>
        demandeContrat && demandeContrat.DevisHamonAssureurActuelId !== undefined
            ? demandeContrat.DevisHamonAssureurActuelId
            : undefined,
);

export const getDevisHamonAssureurActuelNameDemandeContratInit = createSelector(
    getDemandeContratInit,
    (demandeContrat) =>
        demandeContrat && demandeContrat.DevisHamonAssureurActuelName !== undefined
            ? demandeContrat.DevisHamonAssureurActuelName
            : undefined,
);

export const getDevisHamonContratActuelDemandeContratInit = createSelector(getDemandeContratInit, (demandeContrat) =>
    demandeContrat && demandeContrat.DevisHamonContratActuel !== undefined
        ? demandeContrat.DevisHamonContratActuel
        : undefined,
);

export const getNomTitulaireDemandeContratInit = createSelector(
    getDemandeContratInit,
    (contrat) => contrat.NomTitulaire,
);
export const getadrIBANDemandeContratInit = createSelector(getDemandeContratInit, (contrat) => contrat.adrIBAN);
export const getvilleIBANDemandeContratInit = createSelector(getDemandeContratInit, (contrat) => contrat.villeIBAN);
export const getcpIBANDemandeContratInit = createSelector(getDemandeContratInit, (contrat) => contrat.cpIBAN);
export const getIBANDemandeContratInit = createSelector(getDemandeContratInit, (contrat) => contrat.IBAN);
export const getBICDemandeContratInit = createSelector(getDemandeContratInit, (contrat) => contrat.BIC);

export const getTiersPayeurDemandeContratInit = createSelector(
    demandeContratSelector,
    (contrat) => contrat.TiersPayeur || {},
);
export const getTiersPayeurTypeDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.Type,
);
export const getTiersPayeurLastNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.LastName,
);
export const getTiersPayeurBirthNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.BirthName,
);
export const getTiersPayeurFirstNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.FirstName,
);
export const getTiersPayeurBirthDateDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.BirthDate,
);
export const getTiersPayeurBirthCountryDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.BirthCountry,
);
export const getTiersPayeurBirthCityDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.BirthCity,
);
export const getTiersPayeurRelationshipDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.Relationship,
);
export const getTiersPayeurThirdPayerReasonDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    (tiersP) => tiersP.ThirdPayerReason,
);

/* Devis */
export const getDataDevisInit = createSelector(initSelector, (init) => init.data.Devis || {});

export const getUrlPDFDevisInit = createSelector(getDataDevisInit, (devis) => devis.UrlPDFDevis);

export const getIDDevisDevisInit = createSelector(getDataDevisInit, (devis) => devis.IDDevis);

/* Personne Principale */
export const getPersonnePrincipaleDemandeTarifInit = createSelector(
    initSelector,
    (init) => _.find(init.data.DemandeTarif.ListePersonnes, { TypePersonne: 'P', RoleConducteur: 'P' }) || {},
);

export const getNumeroClientPersonnePrincipaleDemandeTarif = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    principale => principale.NumeroClient
)
export const getDejaClientPersonnePrincipaleDemandeTarif = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    principale => principale.DejaClient
)

export const getPersonnePrincipaleIsSouscripteurDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => (principale.Souscripteur !== undefined ? String(principale.Souscripteur) : undefined),
);

export const getCivilitePersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.Civilite,
);
export const getNomPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.Nom,
);

export const getBirthCountryPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.BirthCountry,
);
export const getBirthCityPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.BirthCity,
);

export const getBirthDepartmentPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.BirthDepartment,
);

export const getBirthNamePersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.BirthName,
);

export const getPrenomPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.Prenom,
);
export const getDateNaissancePersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.DateNaissance,
);

export const getValeurCrmMotoPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.ValeurCrmMoto,
);

export const getCrmMoto50Plus3AnsPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.CrmMoto50Plus3Ans,
);
export const getValeurCrmAutoPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.ValeurCrmAuto,
);
export const getCrmAuto50Plus3AnsPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.CrmAuto50Plus3Ans,
);
export const getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.NbMoisAssuranceMoto,
);
export const getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.NbMoisAssuranceAuto,
);

export const getConducteurMineurPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.ConducteurMineur,
);

/* Liste Permis Personne Principale */
export const getFormationPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.Formation || null,
);
export const getDeclarationAssurancePersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.DeclarationAssurance || null,
);
export const getListePermisPersonnePrincipaleDemandeTarifInit = createSelector(
    getPersonnePrincipaleDemandeTarifInit,
    (principale) => principale.ListePermis || [],
);

export const getPermisAMPersonnePrincipaleDemandeTarifInit = createSelector(
    getListePermisPersonnePrincipaleDemandeTarifInit,
    (listePermis) => _.find(listePermis, ['TypePermis', 'AM']) || {},
);

export const getPermisA1PersonnePrincipaleDemandeTarifInit = createSelector(
    getListePermisPersonnePrincipaleDemandeTarifInit,
    (listePermis) => _.find(listePermis, ['TypePermis', 'A1']) || {},
);

export const getPermisA2PersonnePrincipaleDemandeTarifInit = createSelector(
    getListePermisPersonnePrincipaleDemandeTarifInit,
    (listePermis) => _.find(listePermis, ['TypePermis', 'A2']) || {},
);

export const getPermisAPersonnePrincipaleDemandeTarifInit = createSelector(
    getListePermisPersonnePrincipaleDemandeTarifInit,
    (listePermis) => _.find(listePermis, ['TypePermis', 'A']) || {},
);

export const getPermisBPersonnePrincipaleDemandeTarifInit = createSelector(
    getListePermisPersonnePrincipaleDemandeTarifInit,
    (listePermis) => _.find(listePermis, ['TypePermis', 'B']) || {},
);

/* Tuteur  */
export const getTuteurDemandeTarifInit = createSelector(
    initSelector,
    (init) => _.find(init.data.DemandeTarif.ListePersonnes, { TypePersonne: 'P', RoleConducteur: 'N' }) || {},
);
export const getDateNaissanceTuteurDemandeTarifInit = createSelector(
    getTuteurDemandeTarifInit,
    (tuteur) => tuteur.DateNaissance,
);
export const getCiviliteTuteurDemandeTarifInit = createSelector(getTuteurDemandeTarifInit, (tuteur) => tuteur.Civilite);
export const getNomTuteurDemandeTarifInit = createSelector(getTuteurDemandeTarifInit, (tuteur) => tuteur.Nom);
export const getPrenomTuteurDemandeTarifInit = createSelector(getTuteurDemandeTarifInit, (tuteur) => tuteur.Prenom);

export const getBirthCountryTuteurDemandeTarifInit = createSelector(
    getTuteurDemandeTarifInit,
    (tuteur) => tuteur.BirthCountry,
);
export const getBirthCityTuteurDemandeTarifInit = createSelector(
    getTuteurDemandeTarifInit,
    (tuteur) => tuteur.BirthCity,
);
export const getBirthNameTuteurDemandeTarifInit = createSelector(
    getTuteurDemandeTarifInit,
    (tuteur) => tuteur.BirthName,
);

/* Société  */
export const getPersonneMoraleDemandeTarifInit = createSelector(
    initSelector,
    (init) => _.find(init.data.DemandeTarif.ListePersonnes, ['TypePersonne', 'M']) || {},
);

export const getCivilitePersonneMoraleTarif = createSelector(
    getPersonneMoraleDemandeTarifInit,
    (personneM) => personneM.Civilite,
);

export const getNomPersonneMoraleTarif = createSelector(
    getPersonneMoraleDemandeTarifInit,
    (personneM) => personneM.Nom,
);

export const getLegalStatusPersonneMoraleTarif = createSelector(
    getPersonneMoraleDemandeTarifInit,
    (personneM) => personneM.LegalStatus,
);
export const getIdSocietyPersonneMoraleTarif = createSelector(
    getPersonneMoraleDemandeTarifInit,
    (personneM) => personneM.IdSociety,
);

/* Souscripteur */
export const getSouscripteurDemandeTarif = createSelector(
    initSelector,
    (init) => _.find(init.data.DemandeTarif.ListePersonnes, 'Souscripteur') || {},
);

export const getNumeroClientSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.NumeroClient
)

export const getDejaClientSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.DejaClient
)

export const getEmailSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.Email,
);
export const getTelPortableSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.TelPortable,
);
export const getNumPermisSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.NumPermis,
);

export const getNameSouscripteurDemandeTarif = createSelector(getSouscripteurDemandeTarif, (souscripteur) => {
    const prenom = souscripteur.Prenom ? `${souscripteur.Prenom} ` : '';

    return `${prenom}${souscripteur.Nom}`;
});

export const getNomSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur?.Nom
);

export const getAdressSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => `${souscripteur.Adresse1} ${souscripteur.CP} ${souscripteur.Ville}`,
);

export const getCrmAutoSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.ValeurCrmAuto,
);

export const getCrmMotoSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.ValeurCrmMoto,
);
export const getAdresse1SouscripteurDemandeTarifInit = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.Adresse1,
);
export const getAdresse2SouscripteurDemandeTarifInit = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.Adresse2,
);
export const getAdresse3SouscripteurDemandeTarifInit = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.Adresse3,
);
export const getVilleSouscripteurDemandeTarifInit = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.Ville,
);
export const getCPSouscripteurDemandeTarifInit = createSelector(
    getSouscripteurDemandeTarif,
    (souscripteur) => souscripteur.CP,
);

/* Vehicule */
export const getVehiculeDemandeTarifInit = createSelector(
    initSelector,
    (init) => init.data.DemandeTarif.Vehicule || {},
);

export const getDateMECVehiculeDemandeTarif = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.DateMEC,
);

export const getDateAchatVehiculeDemandeTarif = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.DateAchatVehicule,
);

export const getUsageVehiculeDemandeTarif = createSelector(getVehiculeDemandeTarifInit, (vehicule) =>
    vehicule.UsageVehicule ? String(vehicule.UsageVehicule) : '',
);

export const getVersionVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.Version,
);

export const getSituationVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.SituationVehicule,
);

export const getIdentifiantSRAVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.IdentifiantSRA,
);

export const getAssure3DerniersMoisVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.Assure3DerniersMois,
);

export const getUsageNonLivraisonVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.UsageNonLivraison,
);

export const getValeurVehiculeVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.ValeurVehicule,
);

export const getCarteGriseFrancaiseVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.CarteGriseFrancaise,
);

export const getVilleGarageVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.VilleGarage,
);

export const getCodePostalGarageVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.CodePostalGarage,
);

export const getEligibleMultidetentionVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.EligibleMultidetention,
);

export const getContratEligibleDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.ContratEligible,
);

export const getImmatriculationVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.Immatriculation,
);

export const getPossessionPlusDe3MoisVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.possessionPlusDe3Mois,
);


export const getVINVehiculeDemandeTarifInit = createSelector(getVehiculeDemandeTarifInit, (vehicule) => vehicule.VIN);

export const getVehiculeImmatriculeVehiculeDemandeTarifInit = createSelector(
    getVehiculeDemandeTarifInit,
    (vehicule) => vehicule.VehiculeImmatricule,
);
/* Antécédents */
export const getAntecedentsDemandeTarifInit = createSelector(
    initSelector,
    (init) => init.data.DemandeTarif.Antecedents || {},
);

export const getSuspensionPermisAntecedentsDemandeTarifInit = createSelector(
    getAntecedentsDemandeTarifInit,
    (antecedents) => (antecedents.SuspensionPermis !== undefined ? String(antecedents.SuspensionPermis) : undefined),
);
export const getAnnulationPermisAntecedentsDemandeTarifInit = createSelector(
    getAntecedentsDemandeTarifInit,
    (antecedents) => (antecedents.AnnulationPermis !== undefined ? String(antecedents.AnnulationPermis) : undefined),
);
export const getCondamnationAlcoolemieAntecedentsDemandeTarifInit = createSelector(
    getAntecedentsDemandeTarifInit,
    (antecedents) =>
        antecedents.CondamnationAlcoolemie !== undefined ? String(antecedents.CondamnationAlcoolemie) : undefined,
);
export const getCondamnationStupefiantsAntecedentsDemandeTarifInit = createSelector(
    getAntecedentsDemandeTarifInit,
    (antecedents) =>
        antecedents.CondamnationStupefiants !== undefined ? String(antecedents.CondamnationStupefiants) : undefined,
);
export const getCondamnationDelitFuiteAntecedentsDemandeTarifInit = createSelector(
    getAntecedentsDemandeTarifInit,
    (antecedents) =>
        antecedents.CondamnationDelitFuite !== undefined ? String(antecedents.CondamnationDelitFuite) : undefined,
);
export const getCondamnationConduiteSansAssuranceAntecedentsDemandeTarifInit = createSelector(
    getAntecedentsDemandeTarifInit,
    (antecedents) =>
        antecedents.CondamnationConduiteSansAssurance !== undefined
            ? String(antecedents.CondamnationConduiteSansAssurance)
            : undefined,
);

export const getListeSinistresAntecedentsDemandeTarifInit = createSelector(
    getAntecedentsDemandeTarifInit,
    (antecedents) => antecedents.ListeSinistres,
);

/* Police */
export const getPoliceDemandeTarifInit = createSelector(initSelector, (init) => init.data.DemandeTarif.Police || {});
export const getFormuleChoisiePoliceDemandeTarifInit = createSelector(getPoliceDemandeTarifInit, (police) =>
    police.FormuleChoisie !== undefined ? String(police.FormuleChoisie) : undefined,
);
export const getRealisateurDemandeTarifInit = createSelector(
    getPoliceDemandeTarifInit,
    (police) => police.IdRealisateur,
);

export const getFractionnementPoliceDemandeTarifInit = createSelector(
    getPoliceDemandeTarifInit,
    (police) => police.FractionnementChoisi,
);

export const getCanalDemandeTarifInit = createSelector(getPoliceDemandeTarifInit, (police) => police.Canal);

/* ListeOptions  */
export const getListeOptionsDemandeTarifInit = createSelector(
    initSelector,
    (init) => init.data.DemandeTarif.ListeOptions || {},
);
export const getOptionsDemandeTarifInit = createSelector(getListeOptionsDemandeTarifInit, (listeOptions) => {
    let options = {};
    _.forEach(
        _.filter(listeOptions, function (option) {
            return (
                option.Souscrite === true &&
                option.CodeOption !== 'RAFRAVI' &&
                option.CodeOption !== 'RAFRADTA' &&
                option.CodeOption !== 'ACCESSEQP' &&
                !option.DetailOption
            );
        }),
        (option) => {
            options[_.replace(option.CodeOption, /[\d]/g, '')] = option.CodeOption;
        },
    );
    return options;
});

export const getOptionsFranchiseDemandeTarifInit = createSelector(getListeOptionsDemandeTarifInit, (listeOptions) => {
    let options = {};
    _.forEach(
        _.filter(listeOptions, function (option) {
            return option.Souscrite === true && (option.CodeOption === 'RAFRAVI' || option.CodeOption === 'RAFRADTA');
        }),
        (option) => {
            options[option.CodeOption] = true;
        },
    );
    return options;
});

export const getOptionsListeDemandeTarifInit = createSelector(getListeOptionsDemandeTarifInit, (listeOptions) => {
    let options = {};
    _.forEach(
        _.filter(listeOptions, function (option) {
            return (
                option.Souscrite === true &&
                option.CodeOption !== 'RAFRAVI' &&
                option.CodeOption !== 'RAFRADTA' &&
                option.DetailOption
            );
        }),
        (option) => {
            options[option.CodeOption] = option.DetailOption;
        },
    );
    return options;
});

/* Devoir Conseil */
export const getDevoirConseilDemandeTarifInit = createSelector(
    initSelector,
    (init) => init.data.DemandeTarif.DevoirConseil || {},
);
export const getDevoirVolDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) => (devoirConseil.DevoirVol !== undefined ? String(devoirConseil.DevoirVol) : undefined),
);
export const getDevoirDommageDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) => (devoirConseil.DevoirDommage !== undefined ? String(devoirConseil.DevoirDommage) : undefined),
);
export const getDevoirASS2DevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) => (devoirConseil.DevoirASS2 !== undefined ? String(devoirConseil.DevoirASS2) : undefined),
);
export const getDevoirASS3DevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) => (devoirConseil.DevoirASS3 !== undefined ? String(devoirConseil.DevoirASS3) : undefined),
);
export const getDevoirICDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) => (devoirConseil.DevoirIC !== undefined ? String(devoirConseil.DevoirIC) : undefined),
);
export const getDevoirAccEqpDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) =>
        devoirConseil.DevoirACCESSEQP !== undefined ? String(devoirConseil.DevoirACCESSEQP) : undefined,
);
export const getDevoirMontantAccEqpDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) =>
        devoirConseil.DevoirMontantACCESSEQP !== undefined ? Number(devoirConseil.DevoirMontantACCESSEQP) : undefined,
);
export const getDevoirRachatDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    (devoirConseil) => (devoirConseil.DevoirRAFRA !== undefined ? String(devoirConseil.DevoirRAFRA) : undefined),
);

/* Contrat */
export const getContratInit = createSelector(initSelector, (init) => init.data.Contrat || {});

export const getListeDocumentsContratInit = createSelector(
    getContratInit,
    (contrat) =>
        contrat.ListeDocuments?.filter(doc => doc?.Affichage !== false) || []
);

export const getPoliceContratInit = createSelector(getContratInit, (contrat) => contrat.Police);

/* Signatures */
export const getSignaturesInit = createSelector(initSelector, (init) => init.signatures);
export const getUrlSignaturesInit = createSelector(getSignaturesInit, (signatures) =>
    signatures?.length > 0 ? signatures[0].url : null,
);

/* Apporteur 2 */
export const getApporteur2Init = createSelector(initSelector, (init) => init.apporteur2 || null);

/* Apporteur 1 */
export const getApporteur1Init = createSelector(initSelector, (init) => init.apporteur1 || null);

/* Tarif */
export const getTarifInit = createSelector(initSelector, (init) => init.data.Tarif || {});

export const getFeuTricoloreTarifInit = createSelector(getTarifInit, (tarif) => tarif.FeuTricolore);

export const getGestionnaireInit = createSelector(initSelector, (init) => init.gestionnaire);

/* Paiement */
export const getPaiementHistory = createSelector(paiementSelector, (paiement) => _.last(paiement).history);

export const getAmountPaymentsInit = createSelector(paiementSelector, (paiement) => _.last(paiement).amount);

/* whitelabel */
export const getLogoWhitelabel = createSelector(
    initSelector,
    (init) => (init.whitelabel && init.whitelabel.logo_url) || null,
);

export const getNameWhitelabel = createSelector(initSelector, (init) => init.whitelabel.name);
