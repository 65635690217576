import { useImmatWheels } from '@amo/core/api/wheels.js';
import motards from '@amo/core/assets/images/AMO_Icons/Tout-motards.svg';
import { Question } from '@amo/core/components/containers';
import { Radio, Text } from '@amo/core/components/forms/index.js';
import {getTranslation, minLentghImmatriculation, required, validateImmatriculation} from '@amo/core/utils';
import moment from 'moment/moment.js';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import Swal from 'sweetalert2';

const SearchByImmat = (props) => {
    const { nextQuestion, data, changeValue, setVehicule } = props;
    const validImmat = !minLentghImmatriculation(data?.searchImmat) && !validateImmatriculation(data?.searchImmat) && !!data?.searchImmat;
    const [canGoNext, setCanGoNext] = useState(false);
    const [a2filter, setA2filter] = useState("1");
    const { data: responseVehicles, isLoading, isFetched } = useImmatWheels(validImmat, data?.typeVehicule, data?.searchImmat, a2filter);

    const dataVehicles = responseVehicles?.data?.vehicles ?? [];
    const dataFiltered = responseVehicles?.data?.filtered ?? false;

    useEffect (() => {
        if (isFetched) {
            changeValue('immat_log.has_search_immat', true);
            changeValue('immat_log.has_same_stats', responseVehicles?.data?.has_same_stats);
            changeValue('immat_log.search_immat_result_count', dataVehicles.length);
            // has_same_stats doit être remplit par le wheels
            changeValue('immat_log.date_mec_siv', moment(responseVehicles?.data?.date_premiere_immat, 'YYYY-MM-DD').format('DD/MM/YYYY'));
        } else {
            changeValue('immat_log.has_search_immat', false);
            changeValue('immat_log.has_select_immat', false);
            changeValue('immat_log.has_same_stats', false);
            changeValue('immat_log.date_mec_siv', null);
        }
    }, [isFetched]);

    const refreshSearch = () => {
        setA2filter("0");
    };

    const setSelectedVehicle = (vehicle) => {
        changeValue('searchBy', 'immatriculation');
        setVehicule({data: vehicle})

        if (vehicle.souscription_disabled) {
            Swal.fire({
                icon: 'warning',
                iconHtml: '<span class="warning"></span>',
                title: getTranslation("vehicle.searchImmat.modal.title"),
                text: getTranslation("vehicle.searchImmat.modal.text"),
                showConfirmButton: true,
                confirmButtonText: getTranslation("vehicle.searchImmat.modal.confirmButtonText"),
                showCloseButton: true,
            });
            setCanGoNext(false);
        } else {
            changeValue(`code_categorie`, vehicle.codecategorie);
            changeValue(`cylindreeVehicule`, vehicle.cylindree);
            changeValue('DemandeTarif[Vehicule][IdentifiantSRA]', vehicle.sra);
            changeValue('DemandeTarif[Vehicule][DateMEC]', moment(responseVehicles?.data.date_premiere_immat, 'YYYY-MM-DD').format('DD/MM/YYYY'));
            changeValue('DemandeTarif[Vehicule][ValeurVehicule]', vehicle.prix_origine);
            changeValue('DemandeTarif[Vehicule][Immatriculation]', data?.searchImmat);
            changeValue('typeVehicule', vehicle.type.id);

            changeValue('immat_log.has_select_immat', true);
            setCanGoNext(true);
            nextQuestion(300);
        }
    };

    return (
        <Question icon={motards} title={getTranslation("vehicle.searchImmat.title")} bandeau {...props} >
            <div className={'row flex-column justify-content-center align-content-center mt-2'}>
                <div className={'col-lg-8  my-2 d-flex flex-column'}>
                    <Field
                        name={`searchImmat`}
                        label={getTranslation("vehicle.searchImmat.label")}
                        placeholder={'EX: AA 123 BB'}
                        component={Text}
                        type={'text'}
                        typeFormat={'immat'}
                        validate={[minLentghImmatriculation, validateImmatriculation]}
                        required={required}
                    />

                    {isLoading && <span className="mt-2 mx-auto d-block spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"/>}

                    {dataVehicles.length > 0 && isFetched ? (
                        <div className="mt-4">
                            {dataVehicles.map((vehicle, index) => (
                                <Field
                                    key={vehicle.sra}
                                    type={'radio'}
                                    className={`small option w-full`}
                                    label={`${vehicle.brand.name} ${vehicle.modele} ${vehicle.version}`}
                                    icon={vehicle.brand.img}
                                    value={vehicle.sra}
                                    description={`${vehicle.annee_debut_version !== null && vehicle.annee_debut_version !== 0 ? getTranslation("vehicle.modelVehicle.sinceLabelModel", {date: vehicle.annee_debut_version}) : ''} ${vehicle.annee_fin_version !== null && vehicle.annee_fin_version !== 0 ? getTranslation("vehicle.modelVehicle.untilLabelModel", {date: vehicle.annee_fin_version}) : ''} ${vehicle.annee_fin_version || vehicle.annee_debut_version ? '-' : ''} ${vehicle.cylindree} cm3`}
                                    name={'selectVehicle'}
                                    onChange={(e) => setSelectedVehicle(vehicle)}
                                    component={Radio}
                                    validate={required}
                                />
                            ))}
                        </div>
                    ) : isFetched && validImmat ? (
                        <div className={'alert-danger alert mt-1 mb-0'}>
                            {getTranslation("vehicle.searchImmat.noModels")}
                        </div>
                    ) : null}
                    {
                        dataFiltered &&
                        <span className="d-block text-secondary f-13 mt-1">
                            <p className="underline-link cursor-pointer" onClick={refreshSearch} >{getTranslation("vehicle.searchImmat.seeMoreModels")}</p>
                        </span>
                    }
                </div>
            </div>

            <div className="d-flex flex-column align-items-center mt-4">
                <button className={`btn btn-primary btn-arrow  ${!canGoNext && 'disabled'}`} onClick={() => nextQuestion()}>
                    {getTranslation('continue')}
                </button>

                <span
                    type="button"
                    className={`mt-4`}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                        changeValue('searchBy', 'modele');
                    }}
                >
                    {getTranslation("vehicle.searchImmat.iPreferBrandAndModel")}
                </span>
            </div>
        </Question>
    );
};

export default SearchByImmat;
