import { LargeSpinner } from '@amo/core/components/loaders';
import { useEffect } from 'react';
import {getTranslation} from "@amo/core/utils/index.js";

const Validation = (props) => {
    const { query, fetchContratStatut } = props;

    useEffect(() => {
        const timer = setTimeout(() => fetchContratStatut({ query }), 10000);
        return () => clearTimeout(timer);
    });

    return <LargeSpinner message={getTranslation('payment.subscriptionIsInValidation')} />;
};

export default Validation;
