import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const Nom = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-10 col-sm-10 col-12 my-lg-2 my-1'}>
                <Field name={`booking_nom`} label={getTranslation('lastName')} component={Text} type={'text'} required />
            </div>
        </div>
    );
};

export default Nom;
