import { Select } from '@amo/core/components/forms';
import { getCompanys } from '@amo/core/utils/requestsApi.js';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const AncienAssureur = () => {
    const [companys, setCompanys] = useState([]);

    useEffect(() => {
        (async function fetchCompanys() {
            let listCompanys = await getCompanys();
            setCompanys(listCompanys);
        })();
    }, []);

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                <Field
                    label={getTranslation('summary.oldInsurance.label')}
                    help={getTranslation('summary.oldInsurance.help')}
                    name={'DemandeContrat[DevisHamonAssureurActuel]'}
                    component={Select}
                    required
                >
                    <option value={''}>{getTranslation('summary.oldInsurance.select')}</option>
                    {companys.map((item, index) => {
                        if (item.code !== "" && item.libelle !== ""){
                            return (
                                <option key={index} value={`${item.code}|${item.libelle}`}>
                                    {item.libelle}
                                </option>
                            );
                        }
                    })}
                </Field>
            </div>
        </div>
    );
};

export default AncienAssureur;
