import { Assure3DerniersMois, CarteGriseFrancaise, UsageNonLivraison, UsageVehicule, VehiculeImmatricule } from '@amo/core/components/questions';
import _ from 'lodash';
import ConnexionClientContainer from './ConnexionClient/ConnexionClientContainer';
import CylindreeVehiculeContainer from './CylindreeVehicule/CylindreeVehiculeContainer';
import DateAchat from './DateAchat/DateAchat';
import DateMec from './DateMEC/DateMEC';
import GoToProfil from './GoToProfil/GoToProfil';
import GoToVehicule from './GoToVehicule/GoToVehicule';
import LieuStationnementContainer from './LieuStationnement/LieuStationnementContainer';
import MarqueVehiculeContainer from './MarqueVehicule/MarqueVehiculeContainer';
import ModeleVehiculeContainer from './ModeleVehicule/ModeleVehiculeContainer';
import TypeVehicule from './TypeVehicule/TypeVehicule';
import SearchByImmat from './SearchByImmat/SearchByImmat.jsx';
import {getTranslation} from "@amo/core/utils/functions.js";

const Vehicule = (props) => {
    const { data, id, changeValue, previousQuestion } = props;

    return (
        <div id={id}>
            <GoToVehicule {...props} />

            {/*en attente api immat*/}
            {/*<SearchVehiculeByImmatOrModele {...props} />
            { data?.searchBy === "immatriculation" && <SearchByImmat {...props} /> }*/}

            <TypeVehicule {...props} />

            {data.searchBy === 'modele' ? (
                <>
                    {data?.typeVehicule && <MarqueVehiculeContainer {...props} />}
                    {data?.marqueVehicule && <CylindreeVehiculeContainer {...props} />}
                    {data?.cylindreeVehicule && <ModeleVehiculeContainer {...props} />}
                </>
            ) : (
                <SearchByImmat {...props} />
            )}

            <DateMec {...props} />

            <DateAchat {...props} />

            {/* La question est masquée en direct {_.get(data, 'DemandeTarif[ListePersonnes][0][DejaClient]') === '1' &&*/}
            {/*    <Connexion ClientContainer {...props} />}*/}

            {_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') === '1' && <Assure3DerniersMois {...props} />}

            {_.includes(['quad', 'verte'], data?.typeVehicule) && (
                <>
                    {/*<ValeurVehicule title={'Quelle est <b>la valeur</b> de votre <b>véhicule</b>&nbsp;?'} {...props} />*/}
                    <VehiculeImmatricule title={getTranslation("vehicle.registeredVehicle.title")} {...props} />
                </>
            )}

            {_.get(data, 'DemandeTarif[Vehicule][VehiculeImmatricule]') === '1' && <CarteGriseFrancaise {...props} />}

            {!_.includes(['quad', 'verte'], data?.typeVehicule) &&
                <UsageVehicule {...props} title={getTranslation("vehicle.vehicleUse.title")} />
            }

            {String(data?.cylindreeVehicule) === '50' && <UsageNonLivraison {...props} redirectToLivraison={true} />}

            <LieuStationnementContainer {...props} />

            <GoToProfil {...props} />
        </div>
    );
};

export default Vehicule;
