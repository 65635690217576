import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const TiersPayeurThirdPayerReason = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={'DemandeContrat[TiersPayeur][ThirdPayerReason]'}
                    label={getTranslation('summary.interventionReason')}
                    component={Text}
                    type={'text'}
                    maxLength={250}
                />
            </div>
        </div>
    );
};

export default TiersPayeurThirdPayerReason;
