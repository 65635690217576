import info from '@amo/core/assets/images/icons/card.svg';
import { BulleAide } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { maxDate33Days } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const DateHeureEffetHamon = (props) => {
    const { formuleChoisie, fractionnementChoisi, changeValue } = props;

    useEffect(() => {
        changeValue(
            'DemandeContrat[DateHeureEffet]',
            moment().add(33, 'days').startOf('day').format('DD/MM/YYYY HH:mm')
        );
    }, []);

    return (
        <>
            <div className={'row justify-content-center'}>
                <h3 className={'f-16 text-center fw-bold mt-5'}>{getTranslation('summary.hamonTimeEffect.whenStartContract')}</h3>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2 text-start'}>
                    <Field
                        name={`DemandeContrat[DateHeureEffet]`}
                        label={getTranslation('summary.hamonTimeEffect.asapStart')}
                        component={Text}
                        type={'text'}
                        typeFormat={'dateHour'}
                        placeholder={getTranslation('example', {value: '22/03/2023 12:20'})}
                        maxLength={16}
                        validate={maxDate33Days}
                        disabled
                        required
                    />
                </div>
            </div>

            <BulleAide
                text={getTranslation('summary.hamonTimeEffect.firstHelp.text')}
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12 mb-5 mt-3'}
            />

            <BulleAide
                icon={info}
                title={getTranslation('summary.hamonTimeEffect.secondHelp.title')}
                text={fractionnementChoisi === 'M' ? getTranslation('summary.hamonTimeEffect.secondHelp.textMonthly', {price: _.round(formuleChoisie.ComptantMensuelPromo, 2)})
                    : getTranslation('summary.hamonTimeEffect.secondHelp.textAnnual', {price: _.round(formuleChoisie.ComptantAnnuelPromo, 2)})}
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12 mt-3'}
            />
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2 text-start'}>
                    {fractionnementChoisi === 'M' ? (
                        <small>
                            {getTranslation('summary.hamonTimeEffect.secondHelp.confirmPayMonthly', {price: _.round(formuleChoisie.PrimeMensuellePromo, 2)})}
                        </small>

                    ) : (
                        <small>
                            {getTranslation('summary.hamonTimeEffect.secondHelp.confirmPayAnnual', {price: _.round(formuleChoisie.ComptantAnnuel, 2)})}
                        </small>
                    )}
                </div>
            </div>
        </>
    );
};

export default DateHeureEffetHamon;
