/**
 *
 * @param nouveauTypeProduit
 * @param ancienTypeProduit
 * @param situationConducteur
 * 0 conducteur = souscripteur
 * 1 conducteur devient différent de souscripteur
 * 1 changement de conducteur (toujours différent de souscripteur)
 * 2 le conducteur est différent du souscripteur et n'a pas changé
 */

const commonFieldsToComplete = [
    'DemandeTarif.ListePersonnes.0.DejaClientAntecedents',
    'postalCodeCity-lieuStationnement',
    'DemandeTarif.Vehicule.CodePostalGarage',
    'DemandeTarif.Vehicule.VilleGarage',
    'DemandeTarif.Vehicule.EligibleMultidetention',
    'DemandeTarif.Vehicule.ContratEligible',
    'DemandeContrat.NomTitulaire',
    'DemandeContrat.IBAN',
    'DemandeContrat.BIC',
    'DemandeTarif.ListePersonnes.0.Email',
    'DemandeTarif.ListePersonnes.0.TelPortable',
    'DemandeTarif.ListePersonnes.0.TelFixe',
    'DemandeTarif.ListePersonnes.0.Adresse1',
    'DemandeTarif.ListePersonnes.0.Adresse2',
    'DemandeTarif.ListePersonnes.0.CP',
    'DemandeTarif.ListePersonnes.0.Ville',
    'DemandeTarif.ListePersonnes.0.CP',
];

const motoFields = [
    'DemandeTarif.ListePersonnes.0.ValeurCrmMoto',
    'DemandeTarif.ListePersonnes.0.ValeurCrmAuto',
    'DemandeTarif.ListePersonnes.0.NbMoisAssuranceMoto',
    'DemandeTarif.ListePersonnes.0.NbMoisAssuranceAuto'
];

export const getDejaClientFieldStatus = (nouveauTypeProduit, ancienTypeProduit, situationConducteur) => {
    let ret;

    //Si on a comme conducteur le souscripteur
    if(situationConducteur === 0){
        ret = {
            'toComplete': [
                'DemandeTarif.ListePersonnes.0.DateNaissance',
                'DemandeTarif.ListePersonnes.0.ListePermis',
                'DemandeTarif.ListePersonnes.0.NumPermis',
                'DemandeTarif.ListePersonnes.0.Civilite',
                'DemandeTarif.ListePersonnes.0.Nom',
                'DemandeTarif.ListePersonnes.0.BirthName',
                'DemandeTarif.ListePersonnes.0.Prenom',
                'DemandeTarif.ListePersonnes.0.Civilite',
                'DemandeTarif.ListePersonnes.0.BirthCountry',
            ],
            'toEmpty': []
        };

        if(nouveauTypeProduit === 'moto' && ancienTypeProduit === 'moto') {
            ret.toComplete = [
                ...motoFields,
                ...ret.toComplete,
            ];
        } else if(ancienTypeProduit === 'moto') {
            ret.toEmpty = [
                ...motoFields,
                ...ret.toEmpty,
            ];
        }
    }
    //Si on a comme conducteur un mineur ou qu'on a un nouveau conducteur société
    else if(situationConducteur === 1){
        ret = {
            'toComplete': [
                'DemandeTarif.ListePersonnes.1.DateNaissance',
                'DemandeTarif.ListePersonnes.1.Civilite',
                'DemandeTarif.ListePersonnes.1.Nom',
                'DemandeTarif.ListePersonnes.1.BirthName',
                'DemandeTarif.ListePersonnes.1.Prenom',
                'DemandeTarif.ListePersonnes.1.IdSociety',
                'DemandeTarif.ListePersonnes.1.LegalStatus',
                'DemandeTarif.ListePersonnes.1.BirthCountry',
                'DemandeTarif.ListePersonnes.0.BirthCountry',
            ],
            'toEmpty': [
                'DemandeTarif.ListePersonnes.0.DateNaissance',
                'DemandeTarif.ListePersonnes.0.ListePermis',
                'DemandeTarif.ListePersonnes.0.NumPermis',
                'DemandeTarif.ListePersonnes.0.Civilite',
                'DemandeTarif.ListePersonnes.0.Nom',
                'DemandeTarif.ListePersonnes.0.BirthName',
                'DemandeTarif.ListePersonnes.0.Prenom',
                'DemandeTarif.ListePersonnes.0.BirthCountry',
            ]
        };

        if(nouveauTypeProduit === 'moto') {
            ret.toEmpty = [
                ...motoFields,
                ...ret.toEmpty,
            ];
        }
    }
    //Si on a comme conducteur un mineur ou que l'on est dans le cas société et que c'est le même conducteur que celui du déjà client
    else {
        ret = {
            'toComplete': [
                'DemandeTarif.ListePersonnes.1.DateNaissance',
                'DemandeTarif.ListePersonnes.0.DateNaissance',
                'DemandeTarif.ListePersonnes.0.ListePermis',
                'DemandeTarif.ListePersonnes.0.NumPermis',
                'DemandeTarif.ListePersonnes.1.Civilite',
                'DemandeTarif.ListePersonnes.1.Nom',
                'DemandeTarif.ListePersonnes.1.BirthName',
                'DemandeTarif.ListePersonnes.1.Prenom',
                'DemandeTarif.ListePersonnes.1.IdSociety',
                'DemandeTarif.ListePersonnes.1.LegalStatus',
                'DemandeTarif.ListePersonnes.1.BirthCountry',
                'DemandeTarif.ListePersonnes.0.Civilite',
                'DemandeTarif.ListePersonnes.0.Nom',
                'DemandeTarif.ListePersonnes.0.BirthName',
                'DemandeTarif.ListePersonnes.0.Prenom',
                'DemandeTarif.ListePersonnes.0.BirthCountry',
            ],
            'toEmpty': []
        };

        if(nouveauTypeProduit === 'moto' && ancienTypeProduit === 'moto') {
            ret.toComplete = [
                'DemandeTarif.ListePersonnes.0.ValeurCrmMoto',
                'DemandeTarif.ListePersonnes.0.ValeurCrmAuto',
                'DemandeTarif.ListePersonnes.0.NbMoisAssuranceMoto',
                'DemandeTarif.ListePersonnes.0.NbMoisAssuranceAuto',
                ...ret.toComplete,
            ];
        } else if(ancienTypeProduit === 'moto') {
            ret.toEmpty = [
                ...motoFields,
                ...ret.toEmpty,
            ];
        }
    }

    ret.toComplete = [...commonFieldsToComplete, ...ret.toComplete];
    return ret;
}