import {getAddress, getTranslation} from "../../../utils/index.js";
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import _ from "lodash";
import {Text} from "../Text/Text.jsx";
import {Checkbox} from "../Checkbox/Checkbox.jsx";
import {Field} from "redux-form";
import { required } from '@amo/core/utils/validateField';

export const CAPAdresse = (props) => {
    const { setHasSearchAddress, data, changeValue, disabled, path = 'DemandeTarif[ListePersonnes][0]' } = props
    const isFirstRender = useRef(true);

    const [listAddresses, setListAddresses] = useState([]);
    const [addressSelected, setAddressSelected] = useState(null);
    const [localityId, setLocalityId] = useState(null);

    const ville = useMemo(() => _.get(data, `${path}[Ville]`), [data]);
    const codePostal = useMemo(() => _.get(data, `${path}[CP]`), [data]);

    useEffect(() => {
        if (ville && codePostal) {
            setAddressSelected(ville + ' ' + codePostal);
            setHasSearchAddress(true);
        }
    }, [ville, codePostal]);

    useEffect(() => {
        if (isFirstRender.current) {
            // Si c'est le premier rendu, on met la référence à false et on quitte le useEffect
            isFirstRender.current = false;
            return;
        }
        if(_.get(data, `${path}[Ville]`) && _.get(data, `${path}[CP]`)){
            fetchAddress(`${_.get(data, `${path}[Ville]`)} ${_.get(data, `${path}[CP]`)}`, 'city')
        }
    }, [_.get(data, `${path}[Ville]`), _.get(data, `${path}[CP]`)]);

    const fetchAddress = async (value, where) => {
        setAddressSelected(null);
        let addressParams;
        if (where === 'city') {
            addressParams = [value, 'Undefined'];
        } else {
            addressParams = [
                value,
                'Undefined',
                localityId,
                _.get(data, `${path}[CP]`),
                _.get(data, `${path}[Ville]`)
            ];
        }
        let address = await getAddress(...addressParams);
        //SERVICES en panne

        if (address.status === 500) {
            changeValue('addressNotFound', true);
            const element = document.getElementById('addressNotFound');
            if (element) {
                element.style.display = 'none';
            }
        } else {
            if (where === 'city') {
                if (address && address.length > 0) {
                    setLocalityId(address[0].localityId);
                } else {
                    setLocalityId(null);
                }
                setListAddresses([]);
            } else {
                setListAddresses(address?.slice(0, 15));
            }
            setHasSearchAddress(true);
        }
    }

    const selectAddr = (address) => {
        setAddressSelected(address);
        changeValue(`${path}[Adresse1]`, address.additionalInfo_1);
        changeValue(`${path}[Adresse2]`, '');
        changeValue(`${path}[Adresse3]`, address.buildingName || '');
    };

    const validateAddress = useCallback(
        () => (addressSelected || _.get(data, 'addressNotFound') ? undefined : "Veuillez sélectionner une adresse dans la liste"),
        [addressSelected, _.get(data, 'addressNotFound')]
    );

    return (
        <>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mt-3 mb-2'}>
                <Field
                    name={`${path}[Adresse1]`}
                    label={getTranslation('address')}
                    component={Text}
                    type={'text'}
                    placeholder={getTranslation('example', {value: "ENTREE F BATIMENT 5 37 BIS RUE DE LA GARE 75011 PARIS"})}
                    onChange={(e) =>
                        e.target.value.length > 5 && !_.get(data, 'addressNotFound')
                            ? fetchAddress(e.target.value, 'address')
                            : null
                    }
                    validate={[required, validateAddress]}
                    disabled={disabled}
                    required
                />
                {!_.get(data, 'addressNotFound') &&
                    !addressSelected &&
                    _.map(listAddresses, (adresse, i) => (
                        <div key={i} onClick={() => selectAddr(adresse)} className={'list-group'}>
                            <p className={'text-start cursor-pointer m-0 list-group-item list-group-item-action'}>
                                {adresse.inputOutput}
                            </p>
                        </div>
                    ))}
            </div>
            <div className={'row justify-content-center'} id={'addressNotFound'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mb-4 text-start'}>
                    <Field name="addressNotFound" component={Checkbox} disabled={disabled}>
                        <p className={'mt-1'}>{getTranslation('informationsSubscriptor.capAdresse.dontFindAddress')}</p>
                    </Field>
                </div>
            </div>
            {(addressSelected || _.get(data, 'addressNotFound')) && (
                <>
                    <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                        <Field
                            component={Text}
                            type={'text'}
                            name={`${path}[Adresse2]`}
                            label={getTranslation('informationsSubscriptor.capAdresse.additionalAddress')}
                            disabled={(addressSelected && !_.get(data, 'addressNotFound')) || disabled}
                        />
                    </div>
                    <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                        <Field
                            component={Text}
                            type={'text'}
                            name={`${path}[Adresse3]`}
                            normalize={(v) => v.toUpperCase()}
                            label={getTranslation('informationsSubscriptor.capAdresse.buildingStairsFloors')}
                            disabled={(addressSelected && !_.get(data, 'addressNotFound')) || disabled}
                        />
                    </div>
                </>
            )}
        </>
    );
};