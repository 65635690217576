import { LargeSpinner } from '@amo/core/components/loaders';
import _ from 'lodash';
import { useEffect } from 'react';
import TableauGarantiesDesktop from './TableauGarantiesDesktop/TableauGarantiesDesktop';
import TableauGarantiesMobile from './TableauGarantiesMobile/TableauGarantiesMobile';
import {getTranslation} from "@amo/core/utils/index.js";

const Tarif = (props) => {
    const {
        id,
        loaded,
        formulePreconisee,
        codeFormuleChoisie,
        formules,
        changeValue,
        garantiesSelected,
        postReloadTarif,
        queryTarif,
        data,
        loadingFill,
    } = props;

    useEffect(() => {
        //On vérifie si on a une formule sélectionnée et si celle-ci est toujours disponible
        if (formulePreconisee && (!codeFormuleChoisie || !_.find(formules, { CodeFormule: codeFormuleChoisie }))) {
            if (formules.length === 1) {
                changeValue('DemandeTarif[Police][FormuleChoisie]', formules[0].CodeFormule);
            } else {
                changeValue('DemandeTarif[Police][FormuleChoisie]', formulePreconisee);
            }
        }

    }, [formulePreconisee]);

    useEffect(() => {
        if (garantiesSelected) {
            changeGarantiesSelected();
        }

    }, [garantiesSelected]);

    useEffect(() => {
        postReloadTarif({ form: data, query: queryTarif });
        setTimeout(() => {
            document.getElementById('votre-tarif')?.getElementsByClassName('question')[0]?.classList.add('fade-in');
        }, 1000);
    }, []);

    const changeGarantiesSelected = () => {
        let options = {};
        let optionsFranchise = {
            RAFRAVI: 'false',
            RAFRADTA: 'false',
        };
        let optionsListe = {};

        _.map(garantiesSelected, (g) => {
            const id = g.IdGroupeGarantie;

            if (id === 'RAFRAVI' || id === 'RAFRADTA') {
                optionsFranchise[g.CodeGarantie] = 'true';
            } else if (g.listeTarifOption !== undefined && g.DetailOption !== undefined && id === 'ACCESSEQP') {
                optionsListe[id] = g.DetailOption;
            } else {
                options[id] = g.CodeGarantie;
            }
            changeValue(`options`, options);
            changeValue(`optionsFranchise`, optionsFranchise);
            changeValue(`optionsListe`, optionsListe);
        });
    };

    return (
        <div id={id}>
            <div className={' col-12'}>
                {loadingFill ? (
                    <LargeSpinner />
                ) : loaded ? (
                    <>
                       {/* <TableauGarantiesMobile {...props} />*/}

                        <TableauGarantiesDesktop {...props} />
                    </>
                ) : (
                    <LargeSpinner message={getTranslation('pricing.loading')} />
                )}
            </div>
        </div>
    );
};

export default Tarif;
