import avis1 from "@amo/core/assets/images/icons/clients/avis-1.png";
import avis2 from "@amo/core/assets/images/icons/clients/avis-2.png";
import avis3 from "@amo/core/assets/images/icons/clients/avis-3.png";
import {Avis} from "@amo/core/components/containers";

const BlocAvis = () => {
    return (
        <div className={'container mt-5'}>
            <div className={'row'}>
                <Avis
                    name={'Olivia P.'}
                    icon={avis1}
                    content={'Je suis satisfaite des prix scooter électrique. Mon ancienne assurance ne pouvait pas faire mieux. Je recommande APRIL Moto.'}
                    date={'07/01/2025'}
                />
                <Avis
                    name={'Nicolas D.'}
                    icon={avis2}
                    content={'300 euros moins cher que mon ancienne assurance pour des garanties plus que satisfaisantes. Parfait.'}
                    date={'13/01/2025'}
                />
                <Avis
                    name={'Antoine S.'}
                    icon={avis3}
                    content={'Je suis satisfait du service, les prix sont bons et le devis est très rapide, merci. Ravi d\'avoir trouvé APRIL Moto pour assurer mon véhicule.'}
                    date={'16/01/2025'}
                    isLast={true}
                />
            </div>
        </div>
    );
};

export default BlocAvis;
