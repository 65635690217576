import {useQuery} from "@tanstack/react-query";
import {requestServices} from "../utils/request.js";

export const useIban = (iban) =>
    useQuery({
        queryKey: ['iban', iban],
        queryFn: () => requestServices.get(`/iban/${iban}`).then((res) => res.data),
        enabled: !!iban && iban.length >= 14 && iban.length <= 34,
        retry: 0,
    });
