import info from '@amo/core/assets/images/icons/info.svg';
import keys from '@amo/core/assets/images/icons/keys.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/functions.js";

const ConnexionClient = (props) => {
    const { postDejaClient, idDejaClient, loading, nomDejaClient } = props;

    return (
        <Question icon={keys} title={getTranslation("vehicle.connexionClient.title")}>
            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'tel'}
                        name={'DemandeTarif[ListePersonnes][0][NumeroClient]'}
                        placeholder={getTranslation("example", {value: "123456789"})}
                        label={getTranslation("clientNumber")}
                    />
                </div>
            </div>

            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Nom]'}
                        placeholder={getTranslation("example", {value: "Dupont"})}
                        label={getTranslation("lastName", {value: ""})}
                    />
                </div>
            </div>

            {/*<div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Date}
                        name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}
                        placeholder={'Exemple : 22/03/1990'}
                        label={'Date de naissance'}
                    />
                </div>
            </div>*/}

            <BulleAide
                icon={info}
                title={getTranslation("vehicle.connexionClient.help.title")}
            />

            <button
                className={`btn btn-primary btn-arrow mt-4 ${(!idDejaClient || !nomDejaClient) && 'disabled'}`}
                onClick={() => postDejaClient({ idDejaClient, nomDejaClient })}
            >
                {loading ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                ) : (
                    getTranslation("continue")
                )}
            </button>
        </Question>
    );
};

export default ConnexionClient;
