import { Collapse } from '@amo/core/components/containers';
import { RadioCalendrier } from '@amo/core/components/forms';
import moment from 'moment';
import { Field } from 'redux-form';

const Calendrier = () => {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'fr';
    moment.locale(selectedLanguage);

    const today = moment();
    const nextDates = [];
    let currentDate = today;
    while (nextDates.length < 5) {
        currentDate = currentDate.clone().add(1, 'days');
        if (currentDate.day() !== 0) {
            // 0 is sunday
            nextDates.push(currentDate);
        }
    }
    const startTime = moment().hours(9).minutes(0);
    const endTime = moment().hours(18).minutes(0);
    const availableTime = [];
    let currentTime = startTime.clone();
    while (currentTime <= endTime) {
        availableTime.push(currentTime.format('HH:mm'));
        currentTime.add(30, 'minutes');
    }

    const dateAndTime = nextDates.map((date) => ({
        date,
        availableTime: [...availableTime],
    }));

    // remove past hours for the current date
    let todayAvailableTime = [...availableTime];
    todayAvailableTime = todayAvailableTime.filter((time) => {
        const hour = moment(time, 'HH:mm');
        return hour.isAfter(today);
    });
    dateAndTime.unshift({
        date: today,
        availableTime: todayAvailableTime,
    });

    return (
        <div className="row justify-content-center">
            <div className={'col-lg-8 col-md-10 col-sm-10 col-12'}>
                <div className={'row'}>
                    {dateAndTime.map(({ date, availableTime }, index) => (
                        <Collapse title={date.format('dddd D MMM')} isCollapse={false} key={index}>
                            {availableTime.map((time, i) => (
                                <Field
                                    component={RadioCalendrier}
                                    type={'radio'}
                                    name={`booking_heureChoisi`}
                                    value={time + '+' + date.format('DD/MM/YYYY')}
                                    label={time}
                                    key={i}
                                />
                            ))}
                        </Collapse>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Calendrier;
