import {memo, useEffect} from 'react';
import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import {SmallSpinner} from "../../loaders/index.js";
import {useIban} from "../../../api/services.js";

const IbanField =  memo(function IbanField ({formValues, changeValue }) {
    const queryIBAN = useIban(formValues?.['iban']);

    const errorMessage = queryIBAN.error?.response?.data?.message;
    const errorStatus = queryIBAN.error?.status;

    useEffect(() => {
        const detail = queryIBAN.data;

        if (queryIBAN.isSuccess && detail.bic) {
            changeValue('DemandeContrat[IBAN]', detail.iban);
            changeValue('DemandeContrat[BIC]', detail.bic);
            changeValue('domiciliation', detail.bank);
        } else if (queryIBAN.isError && !queryIBAN.isFetching) {
            changeValue('DemandeContrat[BIC]', '');
            changeValue('domiciliation', '');
        }
    }, [queryIBAN.isSuccess, queryIBAN.isError]);

    return (
        <div className="row">
            <div className="col-12 mt-2">
                <Field
                    name={'iban'}
                    component={Text}
                    label={'IBAN'}
                    validate={[
                        (value) => {
                            if (queryIBAN.isError && !queryIBAN.isFetching) {
                                return errorMessage;
                            }
                            if (value && (value.length < 14 || value.length > 34)) {
                                return "L'IBAN doit contenir entre 14 et 34 caractères";
                            }
                        },
                    ]}
                    onChange={(e) => {
                        changeValue('bic', '');
                        changeValue('domiciliation', '');
                    }}
                />
                {queryIBAN.isLoading && <SmallSpinner />}
            </div>
            <div className="col-12 mt-2">
                <Field
                    name={'domiciliation'}
                    component={Text}
                    label={'Domiciliation de la banque'}
                    disabled={errorStatus !== 500}
                    normalize={(v) => v.toUpperCase()}
                />
            </div>
            <div className="col-12 mt-2">
                <Field
                    name={'DemandeContrat[BIC]'}
                    component={Text}
                    label={'Code BIC'}
                    disabled={errorStatus !== 500}
                    normalize={(v) => v.toUpperCase()}
                />
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.formValues === nextProps.formValues;
});

export default IbanField;
