import zarco from '@amo/core/assets/images/icons/zarco.png';
import _ from 'lodash';
import ASAP from './ASAP/ASAP';
import {getTranslation} from "@amo/core/utils/index.js";

const ChoixRappel = (props) => {
    const { setStepForm, data, postDemandeRappel, setShowBooking, apporteur1 } = props;

    const submitBooking = () => {
        if (data.choixCreneau === 'asap') {
            const formData = {};

            formData.nom = data.booking_nom;
            formData.prenom = data.booking_prenom;
            formData.tel = data.booking_tel;
            formData.asap = true;
            formData.ap1 = `${apporteur1?.codeBelair}`;

            postDemandeRappel(formData);
            setShowBooking(false);
        } else {
            setStepForm(3);
        }
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className={'col-12 mb-4'}>
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-4 col-lg-2 text-center'}>
                            <img src={zarco} alt={'être rappelé'} />
                        </div>
                        <div className={'col-8 col-lg-5 text-start'}>
                            <h2 className={'text-dark f-24 fw-bold mt-4 mb-2'}>{getTranslation('callMeBack.title')}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <ASAP {...props} />

            <span
                className={`cursor-pointer btn btn-primary btn-arrow btn-smaller mt-4 mb-2 ${
                    !_.get(data, 'choixCreneau') && 'disabled'
                }`}
                onClick={() => {
                    submitBooking();
                }}
            >
                {getTranslation('next')}
            </span>
        </>
    );
};

export default ChoixRappel;
