import { Autocomplete, Text } from '@amo/core/components/forms';
import { getCountries } from '@amo/core/utils/requestsApi.js';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const TiersPayeurBirthCountry = () => {
    const [countriesFormat, setCountriesFormat] = useState([]);
    const [capencyIsDown, setCapencyIsDown] = useState(false);

    useEffect(() => {
        (async function fetchCountries() {
            let countriesFormat;
            let countries = await getCountries();

            if (countries.status === 500) {
                setCapencyIsDown(true)
            } else {
                setCapencyIsDown(false)
                countriesFormat = countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                }));

                setCountriesFormat(countriesFormat);
            }

        })();
    }, []);

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                {
                    capencyIsDown ? <Field
                        component={Text}
                        typeFormat={'postal'}
                        label={'Pays de naissance'}
                        name={'DemandeContrat[TiersPayeur][BirthCountry]'}
                        placeholder={getTranslation('example', {value: 'France'})}
                        data={countriesFormat}
                    /> : <Field
                        component={Autocomplete}
                        typeFormat={'postal'}
                        label={'Pays de naissance'}
                        name={'DemandeContrat[TiersPayeur][BirthCountry]'}
                        placeholder={getTranslation('example', {value: 'France'})}
                        data={countriesFormat}
                    />
                }
            </div>
        </div>
    );
};

export default TiersPayeurBirthCountry;
