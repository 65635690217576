import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_DEJA_CLIENT, REFRESH_DEJA_CLIENT, setDejaClient} from "../../../actions/app/dejaClient/dejaClient.actions";
import {change} from "redux-form";
import {dejaClient} from "@amo/core/utils/dejaClient.js";
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const dejaClientMiddleware = () => next => action => {
    next(action)

    const { payload } = action;
    let nextAction = [],
        dejaClientListePersonnes,
        listePersonnes,
        typeProduit,
        shouldUpdate

    switch (action.type){
        case POST_DEJA_CLIENT:
            const requestBody = {
                'DemandeTarif': {
                    'ListePersonnes': [
                        {
                            'DejaClientSearch': payload.body.idDejaClient,
                            'Nom': payload.body.nom,
                            'TypePersonne': 'P',
                            'RoleConducteur': 'P'
                        }
                    ]
                }
            };

            next([
                apiRequest({ body: requestBody, method: 'POST', url: payload.data, entity: POST_DEJA_CLIENT, otherData: payload.meta.otherData }),
                setLoader({ state: true, entity: POST_DEJA_CLIENT })
            ]);
            break

        case `${POST_DEJA_CLIENT} ${API_SUCCESS}`:
            dejaClientListePersonnes = payload?.data?.data?.DemandeTarif?.ListePersonnes
            typeProduit = payload.meta.otherData.typeProduit
            listePersonnes = payload.meta.otherData.listePersonnes
            shouldUpdate = payload.meta.otherData?.shouldUpdate !== false

            nextAction = [
                setDejaClient({ data: dejaClientListePersonnes && dejaClientListePersonnes[0].DejaClient ? ({shouldUpdate, ...payload.data.data}) : {} }),
                setError({state: false, entity: POST_DEJA_CLIENT}),
                setLoader({state: false, entity: POST_DEJA_CLIENT})
            ];

            if(shouldUpdate) {
                if (dejaClientListePersonnes && dejaClientListePersonnes[0].DejaClient) {
                    nextAction = dejaClient('devis-direct', false, typeProduit, payload.data.data, nextAction, listePersonnes);
                    // La connexion au déjà client est transparente, on ne fait pas de notification
                } else {
                    nextAction.unshift(change('devis-direct', `DemandeTarif.ListePersonnes.0.NumeroClient`, ''))
                    nextAction.unshift(analyticsSetEvent({event: 'errorMessage', datas: {
                        'errorMessage': {'label': 'Impossible de vous identifier'}
                    }}))
                    nextAction.unshift(setNotification({
                        entity: POST_DEJA_CLIENT,
                        html: '<div><p>Impossible de vous identifier</p></div>',
                        icon: 'error',
                        title: 'Erreur !',
                        confirmButtonText: "J'ai compris",
                    }))
                }
            }
            next(nextAction)
            break

        case `${POST_DEJA_CLIENT} ${API_ERROR}`:
            next([
                analyticsSetEvent({event: 'errorMessage', datas: {
                    'errorMessage': {'label': 'Impossible de vous identifier'}
                }}),
                setDejaClient({data: {}}),
                setNotification({entity: POST_DEJA_CLIENT, html: `<p>Impossible de vous identifier</p>`, type: 'error', title: 'Erreur !', timeout: '10000'}),
                setError({state: true, entity: POST_DEJA_CLIENT}),
                setLoader({state: false, entity: POST_DEJA_CLIENT})
            ])
            break

        case REFRESH_DEJA_CLIENT:
            dejaClientListePersonnes = payload.dejaClientData?.DemandeTarif?.ListePersonnes

            if(dejaClientListePersonnes && dejaClientListePersonnes[0].DejaClient) {
                nextAction = dejaClient('devis-direct', payload.data?.updateOnlyPersonFields, payload.data.typeProduit, payload.dejaClientData, nextAction, payload.data.listePersonnes);
            }

            next(nextAction)
            break

        default:
            return null
    }
}