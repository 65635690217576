import info from '@amo/core/assets/images/icons/card.svg';
import { BulleAide } from '@amo/core/components/containers';
import { Radio, Text } from '@amo/core/components/forms';
import { maxDate30Days, minDateHourToday } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const DateHeureEffet = (props) => {
    const { formuleChoisie, fractionnementChoisi, changeValue, data } = props;

    return (
        <>
            <div className={'row justify-content-center'}>
                <h3 className={'f-16 text-center fw-bold mt-5'}>{getTranslation("summary.whenYouWantStartContract")}</h3>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`debutContrat`}
                        value={'1'}
                        label={getTranslation('now')}
                        className={'small'}
                        onClick={() =>
                            changeValue(
                                'DemandeContrat[DateHeureEffet]',
                                moment().add(15, 'minutes').format('DD/MM/YYYY HH:mm'),
                            )
                        }
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`debutContrat`}
                        value={'0'}
                        label={getTranslation('later')}
                        className={'small'}
                        onClick={() =>
                            changeValue(
                                'DemandeContrat[DateHeureEffet]',
                                moment().add(1, 'days').startOf('day').format('DD/MM/YYYY HH:mm'),
                            )
                        }
                    />
                </div>
            </div>
            {_.get(data, 'debutContrat') && (
                <div className={'row justify-content-center'}>
                    <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                        <Field
                            name={`DemandeContrat[DateHeureEffet]`}
                            label={getTranslation('summary.dateStartContract')}
                            component={Text}
                            type={'text'}
                            typeFormat={'dateHour'}
                            placeholder={getTranslation('example', {value: '22/03/2023 12:20'})}
                            maxLength={16}
                            validate={[maxDate30Days, minDateHourToday]}
                            required
                        />
                    </div>
                </div>
            )}
            <BulleAide
                icon={info}
                title={getTranslation('summary.hamonTimeEffect.secondHelp.title')}
                text={fractionnementChoisi === 'M' ? getTranslation('summary.hamonTimeEffect.secondHelp.textMonthly', {price: _.round(formuleChoisie.ComptantMensuelPromo, 2), nbMois: formuleChoisie.NbMoisComptant === 3 ? 'trois' : 'deux' })
                    : getTranslation('summary.hamonTimeEffect.secondHelp.textAnnual', {price: _.round(formuleChoisie.ComptantAnnuelPromo, 2)})}
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12 mt-3'}
            />

            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2 text-start'}>
                    {fractionnementChoisi === 'M' ? (
                        <small>
                            {getTranslation('summary.hamonTimeEffect.secondHelp.confirmPayMonthly', {price: _.round(formuleChoisie.PrimeMensuellePromo, 2), nbMois: formuleChoisie.NbMoisComptant === 3 ? 'trois' : 'deux' } )}
                        </small>
                    ) : (
                        <small>
                            {getTranslation('summary.hamonTimeEffect.secondHelp.confirmPayAnnual', {price: _.round(formuleChoisie.ComptantAnnuel, 2)})}
                        </small>
                    )}
                </div>
            </div>
        </>
    );
};

export default DateHeureEffet;
