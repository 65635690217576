import Parking from '@amo/core/assets/images/AMO_Icons/Parking.svg';
import { Question } from '@amo/core/components/containers';
import { PostalCodeCity } from '@amo/core/components/forms';
import { excludeCodePostal, excludeCodePostalWith97 } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { getStepBySlug } from '../../../../../../utils/function';
import {getTranslation} from "@amo/core/utils/functions.js";

const LieuStationnement = (props) => {
    const { nextQuestion, data, changeValue, token, canal, goTo, apporteur1 } = props;
    const app1NeedVerifyCP = [809466];

    return (
        <Question icon={Parking} title={getTranslation("vehicle.parkingPlace.title")}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <PostalCodeCity
                        token={token}
                        canal={canal}
                        id={'lieuStationnement'}
                        nameVille={'DemandeTarif[Vehicule][VilleGarage]'}
                        nameCodePostal={'DemandeTarif[Vehicule][CodePostalGarage]'}
                        validate={
                            _.includes(app1NeedVerifyCP, apporteur1.codeBelair)
                                ? excludeCodePostalWith97
                                : excludeCodePostal
                        }
                        placeholder={getTranslation("example", {value: "75011 ou PARIS"})}
                        changeValue={changeValue}
                        defaultValue={
                            _.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]') &&
                            `${_.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]')} ${_.startCase(
                                _.toLower(_.get(data, 'DemandeTarif[Vehicule][VilleGarage]')),
                            )}`
                        }
                    />
                </div>
            </div>

            {_.startsWith(_.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]'), '97') &&
            !_.includes(app1NeedVerifyCP, apporteur1.codeBelair) ? (
                <button
                    className={`btn btn-primary btn-arrow mt-4`}
                    onClick={() => {
                        changeValue('recontactByPostalCode', true);
                        goTo({ step: getStepBySlug('recontact'), question: 0 });
                    }}
                >
                    {getTranslation("continue")}
                </button>
            ) : _.includes(app1NeedVerifyCP, apporteur1.codeBelair) ? (
                <button
                    className={`btn btn-primary btn-arrow mt-4 ${
                        _.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]') &&
                        !excludeCodePostalWith97(_.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]'))
                            ? ''
                            : 'disabled'
                    }`}
                    onClick={() => nextQuestion()}
                >
                    {getTranslation("continue")}
                </button>
            ) : (
                <button
                    className={`btn btn-primary btn-arrow mt-4 ${
                        _.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]') &&
                        !excludeCodePostal(_.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]'))
                            ? ''
                            : 'disabled'
                    }`}
                    onClick={() => nextQuestion()}
                >
                    {getTranslation("continue")}
                </button>
            )}
        </Question>
    );
};

export default LieuStationnement;
