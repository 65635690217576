import fingers from '@amo/core/assets/images/icons/fingers.png';
import {getTranslation} from "@amo/core/utils/index.js";

const EnvoiPieces = (props) => {
    const { documentsUrl } = props;

    return (
        <div id={'etape-documents'}>
            <div className={'row justify-content-center align-items-center mt-5'}>
                <div className={'col-lg-3 offset-lg-1 col-md-6 col-sm-16 col-6 my-2'}>
                    <img src={fingers} alt={'Confirmation devis'} className={'img-fluid'} />
                </div>
                <div className={'col-lg-7 col-md-6 col-sm-16 col-12 my-2 text-lg-start text-dark text-center'}>
                    <h2 className={'my-4 f-22 fw-bold'} dangerouslySetInnerHTML={{__html: getTranslation('sendDocuments.justOneStep')}} />
                    <p className={'f-16 mb-5'} dangerouslySetInnerHTML={{__html: getTranslation('sendDocuments.missDocuments')}}/>
                    <a href={documentsUrl} className={`btn btn-primary btn-smaller btn-arrow`}>
                        {getTranslation('sendDocuments.sendYourDocs')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EnvoiPieces;
