import rc from "@amo/core/assets/images/icons/rachat-franchise.png";
import dr from "@amo/core/assets/images/icons/tchat.svg";
import pjaz from "@amo/core/assets/images/icons/icon-protection-juri.png";
import ass1 from "@amo/core/assets/images/icons/assistance-panne.png";
import vi from "@amo/core/assets/images/icons/Incendie.svg";
import hivern from "@amo/core/assets/images/icons/route.svg";
import cn from "@amo/core/assets/images/AMO_Icons/Cancel.svg";
import ct from "@amo/core/assets/images/AMO_Icons/Sinistres.svg";
import att from "@amo/core/assets/images/AMO_Icons/Warning.svg";
import acces1 from "@amo/core/assets/images/AMO_Icons/icon-remboursement.png";
import casq from "@amo/core/assets/images/AMO_Icons/icon_accesoire.png";
import dta from "@amo/core/assets/images/AMO_Icons/Protection.svg";
import van12 from "@amo/core/assets/images/icons/icon_-valeur-achat.png";

export const getIconByOptions = codeGarantie => {
    let img;

    switch (codeGarantie){
        case "RC":
            img = rc
            break;
        case "DR":
            img = dr
            break;
        case "PJAZ":
            img = pjaz
            break;
        case "ASS1":
            img = ass1
            break;
        case "VI":
            img = vi
            break;
        case "CN":
            img = cn
            break;
        case "CT":
            img = ct
            break;
        case "ATT":
            img = att
            break;
        case "ACCES1":
            img = acces1
            break;
        case "CASQ":
            img = casq
            break;
        case "DTA":
            img = dta
            break;
        case "HIVERN":
            img = hivern
            break;
        case "VAN12":
            img = van12
            break;

        default:

    }

    return img
}