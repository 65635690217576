import React from 'react';
import './franchises.scss';
import Incendie from "@amo/core/assets/images/icons/Incendie.svg";
import Warning from "@amo/core/assets/images/icons/warning.svg";
import {getTranslation} from "@amo/core/utils/index.js";

const FranchisesInfo = (props) => {
    const {VI, DTA} = props;
    let franchisesListe = [];

    const img = {
        VI: Incendie,
        DTA: Warning,
    };

    if(VI){
        franchisesListe = [...franchisesListe, VI];
    }
    if(DTA){
        franchisesListe = [...franchisesListe, DTA];
    }

    return (
        <div id="franchises">
            <p className={'f-18 m-3 m-auto'}
               dangerouslySetInnerHTML={{__html: getTranslation('pricing.descriptionPersonalize')}}/>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-12 text-center">
                        <h4 className="fw-bold text-primary">{getTranslation('pricing.detailFranchise')}</h4>
                    </div>
                </div>
                <div className="row d-flex justify-content-center gap-3 h-100">
                    {franchisesListe?.map((franchise, index) => (
                        <div className={'col-12 col-md-6 col-lg-4 mb-4 d-flex flex-column h-100'} key={index}>
                            <div className={'card p-4 text-center d-flex flex-column h-100'}>
                                <p><img src={img[franchise.IdGroupeGarantie]} alt="Icon" width={64} className="mb-3"/>
                                </p>
                                <h5 className={'fw-bold text-dark'}
                                    style={{minHeight: '50px'}}>{franchise.LibelleBouton}</h5>
                                <p className={'text-grey f-12'}>
                                    {franchise.ConditionsIndemnisation}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={'col-12'}>
                    <p className={'f-14 text-grey text-center mb-3'}>
                        {getTranslation('pricing.textFranchise')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FranchisesInfo;
