import Chat from '@amo/core/assets/images/AMO_Icons/Chat.svg';
import { Question } from '@amo/core/components/containers';
import {getTranslation} from "@amo/core/utils/functions.js";

const GoToProfil = (props) => {
    const { nextQuestion } = props;

    return (
        <Question
            icon={Chat}
            title={getTranslation("vehicle.goToProfil.title")}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'} dangerouslySetInnerHTML={{__html: getTranslation("vehicle.goToProfil.text")}} />
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                {getTranslation("vehicle.goToProfil.button")}
            </button>
        </Question>
    );
};

export default GoToProfil;
