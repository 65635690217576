import { Radio } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/functions.js";

const TitulaireMineur = (props) => {
    const { changeValue, nextQuestion } = props;

    return (
        <>
            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={'titulaireCarteGrise'}
                    value={'3'}
                    label={getTranslation("profil.holderRegistrationVehicle.yourName")}
                    onChange={() => {
                        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                        changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                        changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P');
                        nextQuestion(300);
                    }}
                />
            </div>

            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={'titulaireCarteGrise'}
                    value={'1'}
                    label={getTranslation("profil.holderRegistrationVehicle.childrenName")}
                    onChange={() => {
                        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                        changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                        changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P');
                        nextQuestion(300);
                    }}
                />
            </div>
        </>
    );
};

export default TitulaireMineur;
