import Incendie from '@amo/core/assets/images/icons/Incendie.svg';
import WhiteIncendie from '@amo/core/assets/images/icons/incendie-dark.svg';
import Warning from '@amo/core/assets/images/icons/warning.svg';
import WhiteWarning from '@amo/core/assets/images/icons/warning-dark.svg';
import { getTranslation } from '@amo/core/utils/functions';
import { normalizeEuro } from '@amo/core/utils/normalize';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import { RadioBtn } from '@amo/core/components/forms';
import './franchises.scss';
import add from "@amo/core/assets/images/icons/add.png";

const Franchises = (props) => {
    const { franchises, changeValue, data, formules, codeFormuleChoisie, codePromo, fractionnementChoisi } = props;

    useEffect(() => {
        const arrayFranchises = ['RAFRAVI', 'RAFRADTA'];
        arrayFranchises.forEach((franchise) => {
            if (_.find(franchises.data, ['value', franchise])) {
                if (_.get(data, `optionsFranchise[${franchise}]`)) {
                    changeValue(`optionsFranchise[${franchise}]`, _.get(data, `optionsFranchise[${franchise}]`));
                } else {
                    changeValue(`optionsFranchise[${franchise}]`, 'false');
                }
            } else {
                changeValue(`optionsFranchise[${franchise}]`, null);
            }
        });
    }, [_.get(data, 'DemandeTarif[Police][FormuleChoisie]')]);

    const img = {
        RAFRAVI: {
            normal: Incendie,
            white: WhiteIncendie
        },
        RAFRADTA: {
            normal: Warning,
            white: WhiteWarning
        },
    };

    const getStringFranchise = (code) => {
        const codeGarantie = code === 'RAFRADTA' ? 'DTA' : 'VI';
        const formule = _.find(formules, ['CodeFormule', codeFormuleChoisie]);
        const franchise = _.find(formule.ListeGaranties, ['CodeGarantie', codeGarantie]);
        // capture les chiffres uniquement
        const numbers = franchise.ConditionsIndemnisationSansRachat.match(/\d+/g);

        return numbers
    };

    const getPromoText = (franchise) => {
        const promoApplicable = codePromo?.Eligible && (codePromo?.TypePromo === 'ReductionPourcent' || franchise.dataAffichage.prix > franchise.dataAffichage.prix_promo);
        const prix = normalizeEuro(franchise.dataAffichage.prix_promo);
        const prixNormal = normalizeEuro(franchise.dataAffichage.prix);

        if (promoApplicable) {
            return (
                <>
                    {prix} TTC / {fractionnementChoisi === 'M' ? getTranslation('months') : getTranslation('year')} <br />
                    <i className="text-grey text-decoration-line-through">({getTranslation('pricing.insteadOf')} {prixNormal})</i>
                </>
            );
        }

        return `${prix}&nbsp;TTC/${fractionnementChoisi === 'M' ? getTranslation('months') : getTranslation('year')}`;
    };

    return (
        <div id="franchises">
            <div className="container mt-4">
                <hr className={'mb-4 mt-5'}/>
                <p className={'f-18 m-3 m-auto my-4'}
                   dangerouslySetInnerHTML={{__html: getTranslation('pricing.descriptionPersonalize')}}/>
                <div className="row justify-content-center">
                    {franchises.data?.map((franchise, index) => {
                        const selectedOption = _.get(data, `optionsFranchise[${franchise.value}]`);

                        return (
                            <div
                                className={`col-12 col-md-6 col-xxl-4 col-xl-6 col-lg-5 mb-4 d-flex cursor-pointer`}
                                key={index}
                                onClick={() =>
                                    changeValue(`optionsFranchise[${franchise.value}]`, selectedOption === 'true' ? 'false' : 'true')
                                }
                            >
                                <div
                                    className={`card p-3 text-center h-100 d-flex flex-column justify-content-between ${
                                        selectedOption === 'true' ? 'bg-primary-dark text-white' : 'border-ternary'
                                    }`}
                                >
                                    <p>
                                        {
                                            selectedOption === 'true' ?
                                                <img src={img[franchise.value].white} alt="Icon" width={64}
                                                     className="mb-3"/>
                                                : <img src={img[franchise.value].normal} alt="Icon" width={64}
                                                       className="mb-3"/>
                                        }
                                    </p>
                                    <h5 className={`fw-bold px-2 f-16 ${
                                        selectedOption === 'true' ? 'text-white' : 'text-dark'
                                    }`} style={{minHeight: 60}}>
                                        {franchise.label}
                                    </h5>

                                    <p className={`f-12 mb-3 ${
                                        selectedOption === 'true' ? 'text-white' : 'text-grey'
                                    }`} style={{minHeight: 55}}
                                       dangerouslySetInnerHTML={{
                                           __html: getTranslation(`pricing.franchises.${franchise.value}.text`, {
                                               count: getPromoText(franchise),
                                               countFranchise: getStringFranchise(franchise.value)
                                           })
                                       }}/>
                                    <div className="col-12 p-0 mt-lg-0 mt-4">
                                        <button
                                            className={`cursor-pointer p-3 w-100 h-100 btn ${
                                                selectedOption === 'true' ? 'btn-outline-primary' : 'btn-primary'
                                            } d-block`}
                                        >
                                            <p className="text-left f-13 ms-0 ms-md-3"
                                               onClick={() => changeValue(`optionsFranchise[${franchise.value}]`, selectedOption === 'true' ? 'false' : 'true')}>
                                                {selectedOption === 'false' ? (
                                                    <>
                                                        Activer l'option <img className={"ms-2 align-top"} src={add}
                                                                              alt={'Ajouter l\'option'} width={15}/>
                                                    </>
                                                ) : (
                                                    <><span className={'check-icon w-20'}/> Retirer l'option</>
                                                )}
                                            </p>

                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="f-12 text-grey">
                            {getTranslation('pricing.descriptionsFranchises')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Franchises;
