import { BlocOption } from '@amo/core/components/group-form';
import _ from 'lodash';
import { getIconOptions, getTextOptions } from './getTextOptions';
import './options.scss';
import {getTranslation} from "@amo/core/utils/index.js";
import {getIconByOptions} from "./getIconByOptions.jsx";

const Options = (props) => {
    const { formuleChoisie, options, fractionnementChoisi, changeValue, data, codePromo, apporteur1, TextOptions } = props;

    return (
        <div id={'liste-options'}>
            <div className={`row mt-4`}>
                <p className={'f-20 text-dark fw-bold text-center mb-3 col-12 title-option my-lg-4'} dangerouslySetInnerHTML={{__html: getTranslation('pricing.titleOptions')}} />

                {_.map(options, (option, index) => {
                    if (!option.labelGroupe || option.idGroupe === 'PCC') {
                        return null;
                    }
                    const sousInput =
                        option.data[0].listeTarifOption && option.idGroupe === 'ACCESSEQP'
                            ? option.data[0].listeTarifOption
                            : false;
                    return (
                        <>
                            <BlocOption
                                idGroupe={option.idGroupe}
                                key={index}
                                icon={getIconOptions(option.idGroupe)}
                                title={option.labelGroupe}
                                fractionnement={fractionnementChoisi}
                                name={sousInput ? `optionsListe[${option.idGroupe}]` : `options[${option.idGroupe}]`}
                                data={sousInput ? sousInput : option.data}
                                changeValue={changeValue}
                                selected={_.get(
                                    data,
                                    sousInput ? `optionsListe[${option.idGroupe}]` : `options[${option.idGroupe}]`,
                                )}
                                text={getTextOptions(option.idGroupe, apporteur1.codeBelair)}
                                codePromo={codePromo}
                            />
                        </>
                    );
                })}
            </div>
            <div className={`row mt-4 bg-light text-center p-4 rounded-5`}>
                <div className={'col-12 f-24'} dangerouslySetInnerHTML={{__html: getTranslation('pricing.guaranteesInOffer')}} />
                {_.map(formuleChoisie?.ListeGaranties.filter(g => g.Disponible === 'Inclusion'), ((option, i) => {
                    return (
                        <div className={'col-lg-6 col-md-6 col-12 text-center'} key={i}>
                            <div className="p-3 p-md-4 mx-2 mx-md-5">
                                <p className={'text-dark mx-1 fw-bold my-3'} style={{minHeight: 100}}>
                                    <img className={"my-3 mb-4 d-block mx-auto"} style={{maxWidth: 40}}
                                         src={getIconByOptions(option.CodeGarantie)} alt={option.CodeGarantie}/>
                                    {option.LibelleGarantie}
                                </p>
                                <p className={'f-13 d-inline'} dangerouslySetInnerHTML={{__html: option.texteDeroulant}} />
                            </div>
                        </div>
                    )
                }))}
            </div>
        </div>
    );
};

export default Options;
