import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import _ from "lodash";
import {getTranslation} from "@amo/core/utils/index.js";

const Permis = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={`DemandeTarif[ListePersonnes][0][NumPermis]`}
                    label={getTranslation('informationsSubscriptor.numberLicense')}
                    placeholder={getTranslation('example', {value: "13AA00002"})}
                    component={Text}
                    type={'text'}
                    normalize={v => _.trim(v)}
                />
            </div>
        </div>
    );
};

export default Permis;
