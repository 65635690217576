import tchat from '@amo/core/assets/images/icons/tchat.svg';
import rgpd from '@amo/core/assets/images/logos/logo-rgpd.png';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { validateEmail } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const EmailClient = (props) => {
    const { nextQuestion, data, changeValue } = props;

    return (
        <Question icon={tchat} title={getTranslation('coordonnees.email.title')}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'email'}
                        typeFormat={'email'}
                        name={'DemandeTarif[ListePersonnes][0][Email]'}
                        placeholder={getTranslation('coordonnees.email.addressMail')}
                        validate={validateEmail}
                        onChange={(e) => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                    />
                    <small className={'text-justify d-block mt-1'}>
                        {getTranslation('coordonnees.email.text')}
                    </small>
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    (!_.get(data, 'DemandeTarif[ListePersonnes][0][Email]') ||
                        validateEmail(_.get(data, 'DemandeTarif[ListePersonnes][0][Email]'))) &&
                    'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>

            <BulleAide
                icon={rgpd}
                title={getTranslation('coordonnees.email.help.title')}
                text={getTranslation('coordonnees.email.help.text')}
            />
        </Question>
    );
};

export default EmailClient;
