import {getTranslation} from "@amo/core/utils/index.js";

const MessageNomPrenom = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mb-2'}>
                <div className="alert alert-warning mt-3" role="alert">
                    <p className="mb-0" dangerouslySetInnerHTML={{__html: getTranslation('informationsSubscriptor.sameFirstAndLastName')}}/>
                </div>
            </div>
        </div>
    );
};

export default MessageNomPrenom;
