import { Text } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const TitulaireCompte = (props) => {
    const { nomTitulaireCg, changeValue, isIbanDejaClient } = props;

    const verificationTitulaire = (value) => {
        if (value && _.toUpper(nomTitulaireCg) === _.trim(_.toUpper(value))) {
            changeValue('DemandeContrat[TiersPayeur]', {});
        }
    };

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={`DemandeContrat[NomTitulaire]`}
                    label={getTranslation('summary.lastAndFirstNamePayer')}
                    component={Text}
                    type={'text'}
                    disabled={isIbanDejaClient === true}
                    onChange={(e) => verificationTitulaire(e.target.value)}
                />
            </div>
        </div>
    );
};

export default TitulaireCompte;
