import fingers from '@amo/core/assets/images/icons/fingers.png';
import { getStepBySlug } from '../../../../../utils/function';
import {getTranslation} from "@amo/core/utils/index.js";
import _ from "lodash";

const ConfirmationDevis = (props) => {
    const { email, marque, pdfDevis, goTo } = props;

    const handleResetAndRedirect = () => {
        localStorage.clear();
        window.location = '/'
    };

    return (
        <div id={'etape-confirm-devis'}>
            <div className={'row justify-content-center align-items-center mt-5'}>
                <div className={'col-lg-3 offset-lg-1 col-md-6 col-sm-16 col-12 my-2'}>
                    <img src={fingers} alt={'Confirmation devis'} />
                </div>
                <div className={'col-lg-5 col-md-6 col-sm-16 col-12 my-2 text-start text-dark'}>
                    <p className={'f-14'} dangerouslySetInnerHTML={{__html: getTranslation('confirmQuote.email', {email: email} )}} />
                    <h2 className={'my-4'} dangerouslySetInnerHTML={{__html: getTranslation('confirmQuote.insureImmediatly', {marque: marque})}} />
                    <button
                        className={`btn btn-primary btn-smaller`}
                        onClick={() => goTo({step: getStepBySlug('recapitulatif'), question: 1})}
                    >
                        {getTranslation('payment.iGoToFinalize')}
                    </button>
                    <p className={'underline-link ms-4 cursor-pointer d-inline-block'} style={{fontSize: '14px'}}
                         onClick={() => handleResetAndRedirect()}>
                        {getTranslation('restartQuote')}
                    </p>
                    <a href={pdfDevis} target={'_blank'} download className={'link-secondary f-12 d-block mt-2 ms-2'}>
                        {getTranslation('confirmQuote.downloadQuote')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDevis;
