import SearchVehicule from '@amo/core/assets/images/AMO_Icons/SearchVehicule.svg';
import InformationsBleu from '@amo/core/assets/images/icons/informations-bleu.png';
import { Question } from '@amo/core/components/containers';
import { Autocomplete, Radio } from '@amo/core/components/forms';
import { LargeSpinner } from '@amo/core/components/loaders';
import {getTranslation, showModal} from '@amo/core/utils/functions';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import Swal from 'sweetalert2';
import carte_grise from '@amo/core/assets/images/bulle/modele.jpg';

const ModeleVehicule = (props) => {
    const { setVehicule, changeValue, nextQuestion, previousQuestion, type, fetchModelesVehicule, marque_id, cylindree, modeles, loading, dejaClientRefresh } =
        props;
    const [canGoNext, setCanGoNext] = useState(false);
    const [modelesFiltrees, setModelesFiltrees] = useState([]);

    useEffect(() => {
        if (marque_id) {
            fetchModelesVehicule({ type, marque_id, cylindree });
        }
    }, [type, marque_id, cylindree, fetchModelesVehicule]);

    useEffect(() => {
        let listModeles;

        if (modeles) {
            listModeles = modeles.map((modele) => ({
                value: modele.sra,
                label: `${modele.modele} ${modele.cylindree > 50 ? modele.version : ''}`,
            }));
            setModelesFiltrees(listModeles);
        }
    }, [modeles]);

    const selectModele = (value) => {
        const myModele = _.find(modeles, { sra: value });

        if (myModele.souscription_disabled) {
            Swal.fire({
                icon: 'warning',
                iconHtml: '<span class="warning"></span>',
                title: 'Oups... Erreur de trajectoire',
                text: 'Malheureusement, nous ne pouvons pas vous proposer d’assurance pour ce type de véhicule. Vous pouvez reprendre votre demande de devis en modifiant votre réponse.',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true,
            });
            changeValue('modeleVehicule', null);
            setCanGoNext(false);
        } else {
            if(type !== myModele.type.id) {
                dejaClientRefresh({typeProduit: myModele.type.id});
            }
            changeValue(`code_categorie`, myModele.codecategorie);
            changeValue('DemandeTarif[Vehicule][IdentifiantSRA]', myModele.sra);
            changeValue('typeVehicule', myModele.type.id);
            setCanGoNext(true);
            setVehicule(myModele);
            nextQuestion(300);
        }
    };

    const setLabel = (modele) => {
        let date = `${
            modele.annee_debut_version !== null && modele.annee_debut_version !== 0
                ? getTranslation("vehicle.modelVehicle.sinceLabelModel", {date: modele.annee_debut_version})
                : ''
        } ${
            modele.annee_fin_version !== null && modele.annee_fin_version !== 0
                ? ` ${getTranslation("vehicle.modelVehicle.untilLabelModel", {date: modele.annee_fin_version})}`
                : ''
        }`;

        return `${modele.modele} ${modele.cylindree > 50 ? modele.version : ''} <small class="d-block">${date}</small>`;
    };

    return (
        <Question icon={SearchVehicule} title={getTranslation("vehicle.modelVehicle.title")}>
            <div className={'row justify-content-center mt-2'}>
                <p
                    className={'f-12 text-secondary mb-3'}
                    onClick={() =>
                        showModal({
                            imageUrl: carte_grise,
                            html: getTranslation("vehicle.modelVehicle.registrationVehicleImg"),
                        })
                    }
                >
                    <img src={InformationsBleu} alt="?" className={'align-middle'} />{' '}
                    <span className={'pb-1 cursor-pointer underline-link'}>{getTranslation("vehicle.modelVehicle.whereFindModel")}</span>
                </p>
                {loading ? (
                    <LargeSpinner message={getTranslation("vehicle.modelVehicle.getModels")} />
                ) : (
                    <>
                        <Field
                            name={`modeleVehicule`}
                            component={Autocomplete}
                            data={modelesFiltrees}
                            placeholder={`${getTranslation("example", {value: modelesFiltrees[0]?.label})}`}
                            afterValidate={(value) => selectModele(value)}
                        />

                        {modeles &&
                            _.orderBy(modeles, ['modele']).map((modele, index) => (
                                <div key={index} className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'modeleVehicule'}
                                        value={modele.sra}
                                        label={setLabel(modele)}
                                        onClick={(e) => selectModele(e.target.value)}
                                    />
                                </div>
                            ))}
                    </>
                )}
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!canGoNext && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                {getTranslation("continue")}
            </button>
            <span
                type="button"
                className={`mt-4 d-block`}
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                onClick={() => {
                    previousQuestion(2);
                    changeValue('searchBy', 'immatriculation');
                }}
            >
                    Je préfère rechercher par immatriculation.
                </span>
        </Question>
    );
};

export default ModeleVehicule;
