import info from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { Checkbox } from '@amo/core/components/forms';
import { getAddress, postLogConsent, postRecontactDrom } from '@amo/core/utils/requestsApi';
import { validateImmatriculation, validatePhone } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import Adresse from './Adresse/Adresse';
import {
    Civilite,
    Nom,
    Prenom,
    NomNaissance,
    PaysNaissance,
    VilleNaissance,
    InformationEntreprise, NumeroSerie, Immatriculation, ImmatOuMarquage
} from '@amo/core/components/questions';
import Email from './Email/Email';
import NumeroTel from './NumeroTel/NumeroTel';
import BoutonsAdresseDejaClient from "./BoutonsAdresseDejaClient/BoutonsAdresseDejaClient.jsx";
import MessageNomPrenom from './MessageNomPrenom/MessageNomPrenom.jsx';
import Permis from './Permis/Permis.jsx';
import {getTranslation} from "@amo/core/utils/index.js";


const Recontact = (props) => {
    const { vehicule, data, changeValue, postSaveDevis, token, dejaClientData } = props;
    const isDejaClient = !_.isEmpty(dejaClientData);
    const [hasSearchAddress, setHasSearchAddress] = useState(false);
    const [tryPost, setTryPost] = useState(false);
    const [loadingRecontact, setLoadingRecontact] = useState(false);
    const [emailSend, setEmailSend] = useState(false);
    const recontactByPostalCode = _.get(data, 'recontactByPostalCode') || false;
    const IndexSouscripteur = _.includes(
        ['true', '1', true],
        _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'),
    )
        ? 1
        : 0;

    useEffect(() => {
        if (tryPost) {
            isNotValid();
        }
    }, [data, tryPost]);

    /* Function qui ajoute la class error sur les champs vide */
    const handleError = (fieldName, remove) => {
        _.forEach(document.getElementsByName(fieldName), (input) => {
            if (input.getAttribute('type') === 'radio') {
                if (remove) {
                    input.labels[0].classList.remove('error');
                } else {
                    input.labels[0].classList.add('error');
                }
            } else {
                if (remove) {
                    input.classList.remove('error');
                } else {
                    input.classList.add('error');
                }
            }
        });
    };

    const isNotValid = () => {
        let notValid = false;
        let fields = [
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Civilite]`
                : `DemandeTarif[ListePersonnes][1][Nom]`,
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Nom]`
                : `DemandeTarif[ListePersonnes][1][LegalStatus]`,
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][BirthName]`
                : `DemandeTarif[ListePersonnes][1][IdSociety]`,
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Prenom]`
                : '',
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][BirthCountry]`
                : '',
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][BirthCity]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][Civilite]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][Nom]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][BirthName]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][Prenom]`
                : '',
            `DemandeTarif[ListePersonnes][0][TelPortable]`,
            `DemandeTarif[ListePersonnes][0][Email]`,
            `EmailValidation`,
        ];

        if(isDejaClient) {
            fields.push('DejaClient[Ville]')
            fields.push('DejaClient[CP]')
            fields.push('DejaClient[Adresse1]')
        } else {
            fields.push('DemandeTarif[ListePersonnes][0][Ville]')
            fields.push('DemandeTarif[ListePersonnes][0][CP]')
            fields.push('DemandeTarif[ListePersonnes][0][Adresse1]')
        }

        if (recontactByPostalCode) {
            fields.push('accept-recontact-by-codepostal');
            fields.push('postalCodeCity-codepostal');
        }

        fields.forEach((field) => {
            if (!_.get(data, field) && field !== '') {
                handleError(field);
                notValid = true;
            } else {
                handleError(field, true);
            }
        });

        if (_.get(data, 'immatriculation_check') !== '0') {
            if (validateImmatriculation(_.get(data, 'DemandeTarif[Vehicule][Immatriculation]'))) {
                notValid = true;
            }
        }

        if (validatePhone(_.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]'))) {
            notValid = true;
        }

        if(isDejaClient && _.get(data, 'DejaClient[Validate]') === false) {
            handleError('DejaClient[Adresse1]');
            notValid = true;
        }

        return notValid;
    };

    const myPostSaveDevis = async () => {
        setTryPost(true);
        if (isNotValid()) {
            document.getElementsByClassName('error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            if (hasSearchAddress) {
                const address = _.get(data, 'DejaClient[Validate]') === true
                    ? _.get(data, 'DejaClient[Adresse1]')
                    : _.get(data, 'DemandeTarif[ListePersonnes][0][Adresse1]');
                getAddress(address, 'ValidateForm');
            }
            postSaveDevis();
        }
    };

    async function logConsent(token, phone) {
        await postLogConsent(token, phone);
    }

    const postRecontact = async () => {
        if (isNotValid()) {
            document.getElementsByClassName('error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            let params = data;
            params.vehicule = vehicule;
            let result = await postRecontactDrom(params, setLoadingRecontact, setEmailSend);
            logConsent({ token: token, phone: _.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]') });
        }
    };

    useEffect(() => {
        changeValue('DemandeDevis[TypeMER]', 'DevisOrange');
    }, []);

    return (
        <div id={'etape-recontact'}>
            {emailSend ? (
                <>
                    <div className={'row justify-content-center'}>
                        <div className={'col-lg-6 col-md-6 col-sm-6 col-12 my-2'}>
                            <div className="alert bg-success-amo d-block f-15 text-white text-center" dangerouslySetInnerHTML={{__html: getTranslation('recontact.success')}} />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={'row justify-content-center'}>
                        <div className={'col-lg-6 col-md-6 col-sm-6 col-12 my-2'}>
                            <img src={info} alt={'Devis Orange'} className={'m-2 mb-4'} />
                            <div className="alert bg-blue text-white d-block f-15 text-start">
                                {recontactByPostalCode ? (
                                    <>
                                        <strong>
                                            {getTranslation('recontact.cantInsureTheseVehicles')}
                                        </strong>
                                        <p>
                                            {getTranslation('recontact.advisorContactYou')}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <strong>
                                            {getTranslation('recontact.weCantPropose')}
                                        </strong>
                                        <p>
                                            {getTranslation('recontact.advisorCanRecontact')}
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <Question
                        loader
                        icon={info}
                        title={
                            _.includes(
                                ['true', '1', true],
                                _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'),
                            )
                                ? 'Coordonnées du souscripteur'
                                : 'Vos coordonnées'
                        }
                    >
                        {_.get(data, 'titulaireCarteGrise') !== '2' ? (
                            <>
                                <Civilite {...props} index={IndexSouscripteur} />
                                <Nom {...props} index={IndexSouscripteur} />
                                <NomNaissance {...props} index={IndexSouscripteur} required />
                                <Prenom {...props} index={IndexSouscripteur} />
                                {
                                    (data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Prenom &&
                                        (
                                            _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Nom) === _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Prenom) ||
                                            _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.BirthName) === _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Prenom)
                                        )
                                    ) && <MessageNomPrenom/>
                                }
                                <PaysNaissance {...props} index={IndexSouscripteur} required />
                                <VilleNaissance {...props} index={IndexSouscripteur} required />
                            </>
                        ) : (
                            <>
                                <InformationEntreprise needAdresse={true} isDejaClient={isDejaClient} {...props} />
                            </>
                        )}

                        <Adresse {...props} isDejaClient={isDejaClient} setHasSearchAddress={setHasSearchAddress} />

                        {isDejaClient && <BoutonsAdresseDejaClient hasSearchAddress={hasSearchAddress} handleError={handleError} {...props} />}

                        <NumeroTel {...props} />

                        <Email {...props} />

                        {recontactByPostalCode && (
                            <div className={'row justify-content-center mt-3'}>
                                <div className={'col-lg-8 col-md-10 col-sm-10 col-12 my-lg-2 my-1'}>
                                    <Field name="accept-recontact-by-codepostal" component={Checkbox}>
                                        <p>
                                            {getTranslation('recontact.acceptRecontact')}
                                        </p>
                                        <br />
                                        <small>
                                            {getTranslation('recontact.yourPersonelData')}{' '}
                                            <a
                                                href="https://www.april-moto.com/wp-content/uploads/2021/11/Lettre-RGPD-APRIL-Moto-AM21-11.pdf"
                                                target={'_blank'}
                                            >
                                                {getTranslation('personalDataPolicy')}
                                            </a>
                                        </small>
                                        <p>
                                            <sup className={'text-success'}>{getTranslation('obligatory')}</sup>
                                        </p>
                                    </Field>
                                </div>
                            </div>
                        )}
                    </Question>

                    {(_.includes(
                            ['true', '1', true],
                            _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'),
                        ) ||
                        _.get(data, 'titulaireCarteGrise') === '2') && (
                        <Question icon={info} title={'Coordonnées du conducteur'} loader>
                            <Civilite {...props} index={0} />
                            <Nom {...props} index={0} />
                            <NomNaissance {...props} index={0} required />
                            <Prenom {...props} index={0} />
                            {
                                (data?.DemandeTarif?.ListePersonnes[0]?.Prenom &&
                                    (
                                        _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.Nom) === _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.Prenom) ||
                                        _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.BirthName) === _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.Prenom)
                                    )
                                ) && <MessageNomPrenom/>
                            }
                            <PaysNaissance {...props} index={0} required />
                            <VilleNaissance {...props} index={0} required />
                        </Question>
                    )}

                    <Question title={'Informations complémentaires'} loader>
                        <Permis {...props} />

                        {_.get(data, 'immatriculation_check') === '0' ? (
                            <NumeroSerie {...props} />
                        ) : (
                            <Immatriculation {...props} />
                        )}
                        <ImmatOuMarquage {...props} />
                    </Question>

                    {recontactByPostalCode ? (
                        <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => postRecontact()}>
                            {loadingRecontact ? (
                                <div className="spinner-border" role="status" style={{ width: 19, height: 19 }}>
                                    <span className="sr-only" />
                                </div>
                            ) : (
                                getTranslation('beRecontact')
                            )}
                        </button>
                    ) : (
                        <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => myPostSaveDevis()}>
                            {getTranslation('beRecontact')}
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default Recontact;
