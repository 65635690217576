import { Checkbox, Text } from '@amo/core/components/forms';
import { validatePhone } from '@amo/core/utils/validateField';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const NumeroTel = ({ nameWhitelabel }) => {
    return (
        <>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-10 col-sm-10 col-12 my-lg-2 my-1'}>
                    <Field
                        label={getTranslation('informationsSubscriptor.numberPhone')}
                        component={Text}
                        type={'tel'}
                        typeFormat={'tel'}
                        name={'booking_tel'}
                        placeholder={getTranslation('example', {value: '06 38 53 02 38'})}
                        validate={[validatePhone]}
                        required
                    />
                    <small className={'text-start d-block'}>
                        {getTranslation('callMeBack.phoneUseOurTeam')}
                    </small>
                </div>
            </div>
            <div className={'row justify-content-center mt-3'}>
                <div className={'col-lg-8 col-md-10 col-sm-10 col-12 my-lg-2 my-1'}>
                    <Field name="accept-recontact" component={Checkbox}>
                        <p>
                            {getTranslation('coordonnees.phone.acceptRecontact.accept')}
                        </p>
                        <small dangerouslySetInnerHTML={{__html: getTranslation('coordonnees.phone.acceptRecontact.infos', {nameWhitelabel: nameWhitelabel === 'APRIL Moto' ? 'APRIL Moto' : 'AM Gestion'})}}/>
                        <p>
                            <sup className={'text-success'}>{getTranslation('obligatory')}</sup>
                        </p>
                    </Field>
                </div>
            </div>
        </>
    );
};

export default NumeroTel;
