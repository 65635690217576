import Calendar from '@amo/core/assets/images/AMO_Icons/Calendar.svg';
import info from '@amo/core/assets/images/icons/info.svg';
import InformationsBleu from '@amo/core/assets/images/icons/informations-bleu.png';
import { BulleAide, Question } from '@amo/core/components/containers';
import { DateField } from '@amo/core/components/forms';
import {getTranslation, showModal} from '@amo/core/utils/functions';
import { maxDateToday, minDateMEC } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import carte_grise from '@amo/core/assets/images/bulle/datedemec.jpg';

const DateMec = (props) => {
    const { nextQuestion, data, changeValue } = props;

    const isNotValid = () => {
        const date = _.get(data, 'DemandeTarif[Vehicule][DateMEC]');

        return maxDateToday(date) || (data && minDateMEC(date, data)) || !moment(date, 'DD/MM/YYYY', true).isValid();
    };

    return (
        <Question
            icon={Calendar}
            title={getTranslation("vehicle.dateMec.title")}
        >
            <div className={'row justify-content-center mt-2'}>
                <p
                    className={'f-12 text-secondary mb-3'}
                    onClick={() =>
                        showModal({
                            imageUrl: carte_grise,
                            html: getTranslation("vehicle.dateMec.registrationVehicleImg"),
                        })
                    }
                >
                    <img src={InformationsBleu} alt="?" className={'align-middle'} />{' '}
                    <span className={'pb-1 cursor-pointer underline-link'}>
                        {getTranslation("vehicle.dateMec.whereFindDateMEC")}
                    </span>
                </p>
            </div>

            <BulleAide
                icon={info}
                title={getTranslation("vehicle.dateMec.help.title")}
                text={getTranslation("vehicle.dateMec.help.text")}
                className={'col-lg-6 offset-lg-3 col-md-12 mb-4'}
            />

            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'} data-name={'datemec'}>
                    <Field
                        name={'DemandeTarif[Vehicule][DateMEC]'}
                        component={DateField}
                        placeholder={getTranslation("example", {value: "22/03/2020"})}
                        validate={[maxDateToday, minDateMEC]}
                        minDate={moment('01/01/1990')}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => {
                    const dateMEC = _.get(data, 'DemandeTarif[Vehicule][DateMEC]');
                    changeValue('immat_log.date_mec_reelle', moment(dateMEC, 'DD/MM/YYYY').format('DD/MM/YYYY'));

                    if (
                        moment(dateMEC, 'DD/MM/YYYY', true) >
                        moment().subtract(3, 'months')
                    ) {
                        changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', '0');
                        changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1');
                    } else {
                        changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', null);
                        changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', null);
                    }
                    nextQuestion();
                }}
            >
                {getTranslation("continue")}
            </button>
        </Question>
    );
};

export default DateMec;
