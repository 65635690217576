import Antecedents from './Step/Antecedents/Antecedents';
import Besoins from './Step/Besoins/Besoins';
import ConfirmationDevisContainer from './Step/ConfirmationDevis/ConfirmationDevisContainer';
import Coordonnees from './Step/Coordonnees/Coordonnees';
import EnvoiPiecesContainer from './Step/EnvoiPieces/EnvoiPiecesContainer';
import InformationsSouscripteurContainer from './Step/InformationsSouscripteur/InformationsSouscripteurContainer';
import RecontactContainer from './Step/InformationsSouscripteur/RecontactContainer';
import PaiementContainer from './Step/Paiement/PaiementContainer';
import Profil from './Step/Profil/Profil';
import RecapitulatifContainer from './Step/Recapitulatif/RecapitulatifContainer';
import SignatureContainer from './Step/Signature/SignatureContainer';
import TarifContainer from './Step/Tarif/TarifContainer';
import ValidationContainer from './Step/Validation/ValidationContainer';
import Vehicule from './Step/Vehicule/Vehicule';
import {getTranslation} from "@amo/core/utils/index.js";

let step = 0;

const RouteStep = [
    {
        step: step++,
        slug: 'votre-vehicule',
        title: getTranslation('step.vehicle'),
        main: ({ props, other }) => <Vehicule {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'votre-profil',
        title: getTranslation('step.profil'),
        main: ({ props, other }) => <Profil {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-antecedents',
        title: getTranslation('step.history'),
        main: ({ props, other }) => <Antecedents {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-besoins',
        title: getTranslation('step.needes'),
        main: ({ props, other }) => <Besoins {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-coordonnees',
        title: getTranslation('step.coordonnees'),
        main: ({ props, other }) => <Coordonnees {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'votre-tarif',
        title: getTranslation('step.prices'),
        main: ({ props, other }) => <TarifContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-infos',
        title: getTranslation('step.informations'),
        main: ({ props, other }) => <InformationsSouscripteurContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'recapitulatif',
        title: getTranslation('step.recap'),
        main: ({ props, other }) => <RecapitulatifContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'paiement',
        title: getTranslation('step.payment'),
        main: ({ props, other }) => <PaiementContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'validation',
        title: getTranslation('step.validation'),
        main: ({ props, other }) => <ValidationContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'signature',
        title: getTranslation('step.signature'),
        main: ({ props, other }) => <SignatureContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'envoi-des-pieces',
        title: getTranslation('step.sendDocument'),
        main: ({ props, other }) => <EnvoiPiecesContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'recontact',
        title: getTranslation('step.beRecontact'),
        main: ({ props, other }) => <RecontactContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'confirmation-devis',
        title: getTranslation('step.confirmQuote'),
        main: ({ props, other }) => <ConfirmationDevisContainer {...props} {...other} />,
    },
];

export default RouteStep;
