import { Text } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {anonymizeString} from "@amo/core/utils/index.js";
import IbanField from "@amo/core/components/forms/IbanField/IbanField.jsx";

const Iban = (props) => {
    const { changeValue, data, isIbanDejaClient, isDejaClient, formValues } = props;

    return (
        <div className={'row justify-content-center'}>

            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 mb-2'}>
                {!isIbanDejaClient && <IbanField formValues={formValues} changeValue={changeValue} /> }

                {
                    isIbanDejaClient &&
                        <Field
                            name={`ibanAnonymized`}
                            label={`IBAN`}
                            component={Text}
                            type={'text'}
                            typeFormat={'iban'}
                            input={{
                                value: anonymizeString(isDejaClient.DemandeContrat?.IBAN)
                            }}
                            disabled
                        />
                }

            </div>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 mb-2'}>

                {Boolean(_.get(data, 'DemandeContrat[BIC]')) && (
                    <>
                        {
                            isIbanDejaClient && <Field
                                    component={Text}
                                    name={'bicAnonymized'}
                                    label={`Code BIC`}
                                    normalize={(v) => v.toUpperCase()}
                                    input={{
                                        value: anonymizeString(isDejaClient.DemandeContrat?.BIC)
                                    }}
                                    disabled
                                />
                        }

                    </>
                )}

            </div>
        </div>
    );
};

export default Iban;
