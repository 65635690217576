import { FILL, POST_FILL } from '../../../actions/app/fill/fill.actions';
import { CODEPROMO, postReloadTarif } from '../../../actions/app/tarif/tarif.actions';
import { setError, setIndexQuestion, setLoader, setStep } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import { formatDataForApi } from './formatDataForApi';
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const fillMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        const { payload } = action;
        let nextActions = [];
        let html; let title;

        switch (action.type) {
            case POST_FILL:
                const body = formatDataForApi(payload.body);

                nextActions = [
                    apiRequest({
                        body,
                        method: 'POST',
                        url: payload.data,
                        entity: FILL,
                        otherData: payload.meta.otherData,
                    }),
                ];

                if (payload.meta.otherData && payload.meta.otherData.codePromo) {
                    nextActions.push(setLoader({ state: true, entity: CODEPROMO }));
                } else {
                    nextActions.push(setLoader({ state: true, entity: FILL }));
                }

                next(nextActions);

                break;

            case `${FILL} ${API_SUCCESS}`:
                const { step, queryTarif, form, codePromo } = payload.meta.otherData;

                nextActions = [setError({ state: false, entity: FILL }), setLoader({ state: false, entity: FILL })];

                if (!codePromo) {
                    nextActions.push(setStep({ step: step + 1 }));
                    nextActions.push(setIndexQuestion({ index: 0 }));
                    window.scroll(0, 0);
                }

                next(nextActions);

                if (codePromo) {
                    dispatch(postReloadTarif({ form, query: queryTarif, codePromo, codePromoValue: form.DemandeTarif?.CodePromo?.CodePromo }));
                }
                break;

            case `${FILL} ${API_ERROR}`:

                    if(typeof action.payload?.data?.response?.data?.error === 'string') {
                        html = action.payload?.data?.response?.data?.error
                        title = 'Attention !'
                    } else {
                        html = `<p style='color: #004161;'>Si c'est une erreur de saisie, vous pouvez modifier les informations declarées sur les étapes précédentes</p><small>Erreur n°FILL_APERR</small>`
                        title = 'Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en ligne.'
                    }
                
                next([
                        analyticsSetEvent({event: 'errorMessage', datas: {
                                'errorMessage': {'label': action.payload?.data?.response?.data?.error}
                            }}),
                            setNotification({
                                entity: FILL,
                                html,
                                title,
                                icon: 'error',
                                confirmButtonText: "J'ai compris",
                            }),
                        setError({ state: true, entity: FILL }),
                        setLoader({ state: false, entity: FILL }),
                    ]);
                break;

            default:
                return null;
        }
    };
