import _ from 'lodash';
import {
    AnnulationPermis,
    AssuranceAuto,
    AssuranceMoto,
    CondamnationAlcoolemie,
    CondamnationConduiteSansAssurance,
    CondamnationDelitFuite,
    CondamnationStupefiants,
    GoToBesoin,
    InformationsAnnulationPermis,
    InformationsAssuranceAuto,
    InformationsAssuranceMoto,
    InformationsResiliationAncienAssureur,
    InformationsSuspensionPermis,
    MalusQuadVerte,
    ResiliationAncienAssureur,
    Sinistres,
    SinistresAutoMoto,
    SuspensionPermis,
    TauxAlcool,
    DateSouscriptionContrat
} from "@amo/core/components/questions"
import {useEffect} from "react";

const Antecedents = (props) => {
    const { data, id, changeValue } = props;
    const antecedentsSur3ans =
        _.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') === '1' ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]')) > 1 ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]')) > 1;

    useEffect(() => {
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', antecedentsSur3ans ? '36' : '24')
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', antecedentsSur3ans ? '36' : '24')
    }, [antecedentsSur3ans]);

    return (
        <div id={id}>
            <SuspensionPermis {...props} />
            {_.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1' && (
                <InformationsSuspensionPermis {...props} />
            )}

            <AnnulationPermis {...props} />
            {_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' && (
                <InformationsAnnulationPermis {...props} />
            )}

            {(_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' ||
                _.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1') && (
                <>
                    <CondamnationAlcoolemie {...props} />

                    {_.get(data, 'DemandeTarif[Antecedents][CondamnationAlcoolemie]') === '1' && (
                        <TauxAlcool {...props} />
                    )}
                    <CondamnationStupefiants {...props} />
                    <CondamnationDelitFuite {...props} />
                    <CondamnationConduiteSansAssurance {...props} />
                </>
            )}

            <ResiliationAncienAssureur {...props} />

            {_.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') === '1' && (
                <InformationsResiliationAncienAssureur {...props} />
            )}

            <Sinistres {...props} antecedentsSur3ans={antecedentsSur3ans} />

            {_.get(data, 'sinistre_auto') === '1' && (
                <SinistresAutoMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
            )}

            {_.includes(['quad', 'verte'], data?.typeVehicule) && <MalusQuadVerte {...props} />}

            {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                    return _.includes(['B'], permis?.TypePermis);
                }) && <AssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />}

            {_.get(data, 'assure_auto') === '1' && (
                <InformationsAssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />
            )}

            {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) && <AssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} /> }

            {_.get(data, 'assure_moto') === '1' && (
                <InformationsAssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
            )}

            {
                Number(_.get(data, "DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]")) >= 12 &&
                _.get(data, "titulaireCarteGrise") !== "2" && <DateSouscriptionContrat {...props} />
            }

            <GoToBesoin {...props} />
        </div>
    );
};

export default Antecedents;
