import {Checkbox, Text} from '@amo/core/components/forms';
import { Field } from 'redux-form';
import Swal from "sweetalert2";
import _ from "lodash";
import {useEffect} from "react";
import {getTranslation} from "@amo/core/utils/index.js";

const NumeroContrat = (props) => {
    const { data, changeValue } = props

    const showPopup = e => {
        if(e.currentTarget.value === "false"){
            changeValue('DemandeContrat[DevisHamonContratActuel]', "")
            Swal.fire({
                icon: 'info',
                text: getTranslation('summary.contractNumber.modal.text'),
                confirmButtonText: getTranslation('iReallyUnderstand')
            });
        }
    }

    return (
        <>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 mb-2'}>
                    <Field
                        name={`DemandeContrat[DevisHamonContratActuel]`}
                        label={getTranslation('summary.contractNumber.currentNumberContract')}
                        component={Text}
                        type={'text'}
                        help={getTranslation('summary.contractNumber.help')}
                        required={!_.get(data, "dont-know-contract-number")}
                        disabled={_.get(data, "dont-know-contract-number")}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className={'col-12 col-lg-8 my-2'}>
                    <Field name="dont-know-contract-number" component={Checkbox} onChange={e => showPopup(e)}>
                        <p className={"cursor-pointer"}>{getTranslation('summary.contractNumber.iDontKnowNumber')}</p>
                    </Field>
                </div>
            </div>
        </>
    );
};

export default NumeroContrat;
