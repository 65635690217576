import { BlocOption } from '@amo/core/components/group-form';
import { useOnClickOutside } from '@amo/core/utils/hooks.js';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import { getIconOptions, getTextOptions } from '../Options/getTextOptions';
import {getTranslation, hameconnage} from "@amo/core/utils";

const LastOptions = (props) => {
    const { data, changeValue, fractionnementChoisi, options, setShowLastOptions, postFill, typeSubscribe, codePromo, analyticsSetEvent } =
        props;
    const optionsChoisi = _.get(data, 'options');
    const [nbOptionSelectable] = useState(!optionsChoisi?.ASS + !optionsChoisi?.PCC);
    const ref = useRef();
    useOnClickOutside(ref, () => setShowLastOptions(false));
    const [upsell , setUpsell] = useState(false);
    const [showASS , setShowASS] = useState(false);
    const [showPCC , setShowPCC] = useState(false);

    useEffect(() => {
        if(nbOptionSelectable !== (!optionsChoisi?.ASS + !optionsChoisi?.PCC)){
            setUpsell(true)
        }
    }, [optionsChoisi?.PCC, optionsChoisi?.ASS]);

    useEffect(() => {
        if (!optionsChoisi?.ASS && _.find(options, ['idGroupe', 'ASS'])){
            setShowASS(true)
        }
        if (!optionsChoisi?.PCC && _.find(options, ['idGroupe', 'PCC'])){
            setShowPCC(true)
        }
    }, []);


    return (
        <div style={{ background: 'rgba(0,0,0,.4)' }} className={'position-fixed bottom-0 top-0 start-0 end-0 p-4'}>
            <div
                className={'bg-white p-4 pb-2 overflow-scroll'}
                style={{
                    maxHeight: '90vh',
                    borderRadius: 20,
                    boxShadow: '0px 14px 50px rgb(0 0 0 / 15%)',
                    width: '90em',
                    maxWidth: '100%',
                    margin: '0 auto',
                }}
                ref={ref}
            >
                <h2 className={'text-dark f-24'} style={{ fontWeight: 600 }} dangerouslySetInnerHTML={{__html: getTranslation('pricing.subscribe.haveYouThoughtOfEverything')}}/>
                <p className="f-14 text-secondary mb-4">{getTranslation('pricing.subscribe.complementary')}</p>
                <div className="row justify-content-center">
                    {showPCC && (
                        <BlocOption
                            icon={getIconOptions('PCC')}
                            title={_.find(options, ['idGroupe', 'PCC']).labelGroupe}
                            fractionnement={fractionnementChoisi}
                            name={`options[PCC]`}
                            data={_.find(options, ['idGroupe', 'PCC']).data}
                            changeValue={changeValue}
                            selected={_.get(data, `options[PCC]`)}
                            text={getTextOptions('PCC')}
                            codePromo={codePromo}
                        />
                    )}

                    {showASS && (
                        <BlocOption
                            icon={getIconOptions('ASS')}
                            title={_.find(options, ['idGroupe', 'ASS']).labelGroupe}
                            fractionnement={fractionnementChoisi}
                            name={`options[ASS]`}
                            data={_.find(options, ['idGroupe', 'ASS']).data}
                            changeValue={changeValue}
                            selected={_.get(data, `options[ASS]`)}
                            text={getTextOptions('ASS')}
                            codePromo={codePromo}
                        />
                    )}
                </div>

                <button
                    className={`btn btn-primary btn-smaller btn-arrow mb-2`}
                    onClick={() => {
                        analyticsSetEvent({event: 'modalPense', datas: {
                            'modalPense' : {
                                'upsell' : upsell ? 'Avec upsell' : 'Aucun upsell'
                            }
                        }})
                        setShowLastOptions(false);
                        changeValue('typeDevis', typeSubscribe);
                        postFill({ getTarif: false });
                    }}
                >
                    {getTranslation('pricing.subscribe.itsOkForMe')}
                </button>
            </div>
        </div>
    );
};

export default LastOptions;
