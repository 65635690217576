import keys from '@amo/core/assets/images/AMO_Icons/SearchVehicule.svg';
import carte_grise from '@amo/core/assets/images/bulle/marque.jpg';
import InformationsBleu from '@amo/core/assets/images/icons/informations-bleu.png';
import { Question } from '@amo/core/components/containers';
import { Autocomplete, Radio } from '@amo/core/components/forms';
import { LargeSpinner } from '@amo/core/components/loaders';
import {getTranslation, showModal} from '@amo/core/utils/functions';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { isFavoriteBrand } from './setFavoritesMarques';

const MarqueVehicule = (props) => {
    const { nextQuestion, type, fetchMarquesVehicule, marques, marque, loading, data, changeValue, apporteur1, previousQuestion } = props;
    const [marquesFiltrees, setMarquesFiltrees] = useState([]);

    useEffect(() => {
        fetchMarquesVehicule({ type });
    }, [type, fetchMarquesVehicule]);

    useEffect(() => {
        let listMarques;

        if (marques) {
            listMarques = marques.map((marque) => ({
                value: marque.name,
                label: marque.name,
            }));
            setMarquesFiltrees(listMarques);
        }
    }, [marques]);

    return (
        <>
            <Question icon={keys} title={getTranslation("vehicle.marqueVehicle.title")}
                      bandeau {...props}>
                <div className={'row justify-content-center mt-2'}>
                    <p
                        className={'f-12 text-secondary mb-3'}
                        onClick={() =>
                            showModal({
                                imageUrl: carte_grise,
                                html: getTranslation("vehicle.marqueVehicle.registrationVehicleImg"),
                            })
                        }
                    >
                        <img src={InformationsBleu} alt="?" className={'align-middle'}/> <span
                        className={'pb-1 cursor-pointer underline-link'}>{getTranslation("vehicle.marqueVehicle.whereFindBrand")}</span>
                    </p>
                    {loading ? (
                        <LargeSpinner message={getTranslation("vehicle.marqueVehicle.getBrands")}/>
                    ) : (
                        <>
                            <Field
                                name={`marqueVehicule`}
                                component={Autocomplete}
                                data={marquesFiltrees}
                                placeholder={getTranslation("example", {value: 'BMW'})}
                                afterValidate={(value) => {
                                    changeValue('marqueIdVehicule', _.find(marques, ['name', value]).id);
                                    nextQuestion(300);
                                }}
                            />

                            {_.find(marques, ['name', data.marqueVehicule]) && !isFavoriteBrand({
                                marque: data.marqueVehicule,
                                type: type
                            }) && (
                                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'marqueVehicule'}
                                        value={_.find(marques, ['name', data.marqueVehicule]).name}
                                        label={_.find(marques, ['name', data.marqueVehicule]).name}
                                        icon={_.find(marques, ['name', data.marqueVehicule]).img}
                                        onClick={() => {
                                            changeValue('marqueIdVehicule', _.find(marques, ['name', data.marqueVehicule]).id);
                                            nextQuestion(300);
                                        }}
                                    />
                                </div>
                            )}
                            {marques &&
                                marques.map((marque, index) => (
                                    <Fragment key={index}>
                                        {isFavoriteBrand({marque: marque.name, type: type}) && (
                                            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                                                <Field
                                                    component={Radio}
                                                    type={'radio'}
                                                    name={'marqueVehicule'}
                                                    value={marque.name}
                                                    label={marque.name}
                                                    icon={marque.img}
                                                    onClick={() => {
                                                        changeValue('marqueIdVehicule', marque.id);
                                                        nextQuestion(300);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                        </>
                    )}
                </div>

                <button
                    className={`btn btn-primary btn-arrow mt-4 ${!marque && 'disabled'}`}
                    onClick={() => {
                        nextQuestion();
                        changeValue('marqueIdVehicule', _.find(marques, ['name', marque]).id);
                    }}
                >
                    {getTranslation("continue")}
                </button>
                <span
                    type="button"
                    className={`mt-4 d-block`}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                    onClick={() => {
                        changeValue('searchBy', 'immatriculation');
                    }}
                >
                    {getTranslation("vehicle.marqueVehicle.iPreferRegistration")}
                </span>
            </Question>
        </>
    );
};

export default MarqueVehicule;
