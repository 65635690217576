import { Select } from '@amo/core/components/forms';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";

const TiersPayeurRelationship = (props) => {
    const { data, changeValue } = props;

    useEffect(() => {
        if (_.get(data, 'DemandeContrat[TiersPayeur][Relationship]') !== 'A') {
            changeValue('DemandeContrat[TiersPayeur][ThirdPayerReason]', '');
        }
    }, [_.get(data, 'DemandeContrat[TiersPayeur][Relationship]')]);

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    component={Select}
                    label={getTranslation('relationship')}
                    name={`DemandeContrat[TiersPayeur][Relationship]`}
                >
                    <option value={''}>{getTranslation('summary.selectRelationShip.default')}</option>
                    <option value="C">{getTranslation('summary.selectRelationShip.spouse')}</option>
                    <option value="E">{getTranslation('summary.selectRelationShip.child')}</option>
                    <option value="FS">{getTranslation('summary.selectRelationShip.brotherSister')}</option>
                    <option value="GP">{getTranslation('summary.selectRelationShip.grandParent')}</option>
                    <option value="P">{getTranslation('summary.selectRelationShip.motherFather')}</option>
                    <option value="A">{getTranslation('summary.selectRelationShip.others')}</option>
                </Field>
            </div>
        </div>
    );
};

export default TiersPayeurRelationship;
